<template>
  <div>
    <div class="mb-3">
      <h5>Manage Task Types - {{titleText}}</h5>
    </div>
    <div class="text-center" v-if="isInitialisingUI">
      <b-spinner label="Spinning"></b-spinner>
    </div>
    <div v-else>
      <div v-if="taskType">
        <b-form
          autocomplete="off"
          v-on:submit="formSubmit">
          <AdminDropdown for="sections"
            preventSelectDefaultItem
            v-on:dropdownItemChanged="selectedSectionChanged" />
          <b-form-group label="Task Type Name:"
            label-cols-lg="2"
            content-cols-lg="10">
            <b-form-input
              v-model="taskType.name"
              placeholder="Enter name"
              required>
            </b-form-input>
          </b-form-group>

          <div v-if="isNew">
            <b-form-group label="Instructions document (PDF):"
              label-cols-lg="2"
              content-cols-lg="10">
              <b-form-file
                required
                accept="application/pdf"
                v-model="taskType.file"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here...">
              </b-form-file>
            </b-form-group>
          </div>
          <div v-else>
            <b-form-group
            label="Uploaded Instructions document:">
              <b-link v-on:click="openFile">
                Instructions Document
                <b-spinner v-if="isDownloadingFile" label="Spinning" small />
                <b-icon-download v-else />
              </b-link>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="updateExistingFile">
                  Change uploaded file?
                </b-form-checkbox>
            </b-form-group>
            <b-collapse v-model="updateExistingFile" class="mt-2">
              <b-form-group label="Instructions document:"
                label-cols-lg="2"
                content-cols-lg="10">
                <b-form-file
                  accept="application/pdf"
                  v-model="taskType.file"
                  placeholder="Choose a file or drop it here..."
                  drop-placeholder="Drop file here...">
                </b-form-file>
              </b-form-group>
            </b-collapse>
          </div>

          <b-button class="mr-2"
            title="Cancel"
            v-bind:disabled="taskTypesIsLoading"
            v-on:click="cancelClick">
            <b-icon-x />
          </b-button>
          <b-button class="mr-2" type="submit" variant="primary"
            v-bind:title="submitText"
            v-bind:disabled="taskTypesIsLoading">
            <span v-if="isNew"><b-icon-plus /></span>
            <span v-else><b-icon-check /></span>
            <span v-if="taskTypesIsLoading"><b-spinner label="Spinning" small></b-spinner></span>
          </b-button>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>

import { mapActions } from 'vuex';
import AdminDropdown from '../components/AdminDropdown.vue';
import fileHandlerMixin from '../mixins/fileHandlerMixin';

export default {
  name: 'TaskTypeDetails',
  components: { AdminDropdown },
  mixins: [fileHandlerMixin],
  data() {
    return {
      isInitialisingUI: false,
      updateExistingFile: false,
    };
  },
  props: {
    taskType: {},
    new: { type: Boolean },
  },
  created() {
    if (!this.taskType) {
      this.$router.go(-1);
    } else {
      this.initialiseUI();
    }
  },
  computed: {
    isNew() {
      return this.new;
    },
    sections() {
      return this.$store.state.sections.sections;
    },
    taskTypesIsLoading() {
      return this.$store.state.taskTypes.isLoading;
    },
    isDownloadingFile() {
      return this.$store.state.taskTypes.isDownloadingFile;
    },
    titleText() {
      return this.new === true ? 'Add Task Type' : 'Edit Task Type';
    },
    submitText() {
      return this.new === true ? 'Add Task Type' : 'Update Task Type';
    },
  },
  methods: {
    ...mapActions('sections', ['getSections']),
    ...mapActions('taskTypes', ['createTaskType', 'updateTaskType', 'getTaskTypeInstructions']),
    async initialiseUI() {
      this.isInitialisingUI = true;

      await this.getSections()
        .then(() => {
          this.$store.dispatch('sections/changeSelectedSection', this.taskType.sectionId);
        })
        .catch((error) => {
          this.handleHttpError(error);
          this.$store.dispatch('sections/clearState');
          this.$store.dispatch('taskTypes/clearState');
        });

      this.isInitialisingUI = false;
    },
    selectedSectionChanged() {
      this.taskType.sectionId = this.$store.state.sections.selectedSectionId;
    },
    cancelClick() {
      this.$router.go(-1);
    },
    async formSubmit(event) {
      event.preventDefault();

      if (this.new) {
        await this.createTaskType(this.taskType)
          .then(() => { this.$router.go(-1); })
          .catch((error) => { this.handleHttpError(error); });
      } else {
        await this.updateTaskType(this.taskType)
          .then(() => { this.$router.go(-1); })
          .catch((error) => { this.handleHttpError(error); });
      }
    },
    async openFile() {
      await this.getTaskTypeInstructions(this.taskType.id)
        .then((response) => { this.openFile(response); })
        .catch((error) => { this.handleHttpError(error); });
    },
  },
};
</script>
