import axios from 'axios';

export default {
  namespaced: true,
  state: {
    isLoading: false,
    isDownloadingFile: false,
  },
  mutations: {
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    setIsDownloadingFile(state, isDownloadingFile) {
      state.isDownloadingFile = isDownloadingFile;
    },
  },
  actions: {
    getHelpDocument(context) {
      context.commit('setIsDownloadingFile', true);
      return axios.get('/api/systemdocuments/help', {
        responseType: 'blob',
      })
        .then((response) => response)
        .catch((error) => new Promise((resolve) => {
          const reader = new FileReader();
          reader.onload = () => {
            // eslint-disable-next-line no-param-reassign
            error.response.data = JSON.parse(reader.result);
            resolve(Promise.reject(error));
          };
          reader.readAsText(error.response.data);
        }))
        .finally(() => {
          context.commit('setIsDownloadingFile', false);
        });
    },
    updateHelpDocument(context, file) {
      context.commit('setIsLoading', true);

      const formData = new FormData();
      formData.append('file', file);

      return axios.put('/api/systemdocuments/help', formData)
        .catch((error) => { throw error; })
        .finally(() => {
          context.commit('setIsLoading', false);
        });
    },
    getManualDocument(context) {
      context.commit('setIsDownloadingFile', true);
      return axios.get('/api/systemdocuments/manual', {
        responseType: 'blob',
      })
        .then((response) => response)
        .catch((error) => new Promise((resolve) => {
          const reader = new FileReader();
          reader.onload = () => {
            // eslint-disable-next-line no-param-reassign
            error.response.data = JSON.parse(reader.result);
            resolve(Promise.reject(error));
          };
          reader.readAsText(error.response.data);
        }))
        .finally(() => {
          context.commit('setIsDownloadingFile', false);
        });
    },
    updateManualDocument(context, file) {
      context.commit('setIsLoading', true);

      const formData = new FormData();
      formData.append('file', file);

      return axios.put('/api/systemdocuments/manual', formData)
        .catch((error) => { throw error; })
        .finally(() => {
          context.commit('setIsLoading', false);
        });
    },
  },
};
