<template>
  <div>
    <div class="mb-3">
      <h3>Your Selected Worksheets</h3>
    </div>

    <div class="text-center" v-if="showPageLoadingSpinner">
      <b-spinner label="Spinning"></b-spinner>
    </div>
    <div v-else>
      <b-card>
        <div v-if="isCartContextConfirmed">
          <b-card-title>
            <h5>{{section.name}}: {{taskType.name}}
              <b-link
              v-on:click="downloadInstructionsFile"
              title="Download Instructions Document">
                <b-spinner v-if="isDownloadingInstructionsFile" label="Spinning" small />
                <b-icon-download v-else />
              </b-link>
            </h5>
          </b-card-title>
          <b-table bordered
            stacked="md"
            v-bind:items="worksheets"
            v-bind:fields="tableFields"
            v-bind:busy="tableIsBusy"
            v-bind:current-page="pageNumber"
            v-bind:per-page="itemsCountPerPage"
            show-empty
            empty-text="No selected worksheets">
            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle"></b-spinner>
              </div>
            </template>
            <template #cell(actions)="data">
              <b-button class="mr-2" size="sm" variant="info"
                title="Download Worksheet"
                v-on:click="downloadWorksheetClick(data.item)">
                <b-icon-download />
              </b-button>
              <b-button
                class="mr-2" size="sm" variant="danger"
                title="Remove from Selection"
                v-on:click="removeWorksheet(data.item)">
                <b-icon-cart-dash />
              </b-button>
            </template>
            <template #table-caption>
              <b-button class="mr-2" size="sm" variant="danger"
                title="Remove all from Selection"
                v-if="removeAllButtonIsVisible"
                v-on:click="removeAllWorksheetsClick">
                Remove all <b-icon-cart-x-fill />
              </b-button>
            </template>
          </b-table>
          <b-pagination
            v-model="pageNumber"
            v-bind:total-rows="totalWorksheetsCount"
            v-bind:per-page="itemsCountPerPage"
            v-bind:disabled="tableIsBusy"
            align="center">
        </b-pagination>
        </div>
        <div v-else>
          No selected worksheets
        </div>
        <div class="text-center">
          <b-button variant="secondary"
            class="mr-2"
            v-on:click="backToCatalogueClick">
            Back to Catalogue
          </b-button>
          <b-button variant="primary"
            class="mr-2"
            v-on:click="createDownloadablePackClick"
            v-bind:disabled="createDownloadablePackButtonIsDisabled">
            Create Downloadable Pack
          </b-button>
        </div>
      </b-card>
    </div>

    <b-modal
      title="Create Downloadable Pack"
      size="lg"
      v-model="showCreateDownloadablePackModal"
      v-on:show="createDownloadablePackModalReset"
      v-on:hidden="createDownloadablePackModalReset"
      v-on:ok="createDownloadablePackModalHandleOk">
      <p>
        This will collate your selected Worksheets into a new Downloadable Pack, which can be
        downloaded from the My Downloadable Packs page.
      </p>
      <p>
        Please provide a name for your Downloadable Pack.
      </p>
      <hr />
      <b-form ref="createDownloadablePackModalForm"
        autocomplete="off"
        v-on:submit.stop.prevent="createDownloadablePackModalHandleSubmit">
        <b-form-group
          label="Name:"
          invalid-feedback="Name is required"
          v-bind:state="createDownloadablePackModal_nameState">
          <b-form-input
            v-model="createDownloadablePackModal_name"
            v-bind:state="createDownloadablePackModal_nameState"
            required
          ></b-form-input>
        </b-form-group>
      </b-form>
    </b-modal>

  </div>
</template>

<script>
import { mapActions } from 'vuex';
import fileHandlerMixin from '../mixins/fileHandlerMixin';

export default {
  name: 'Cart',
  mixins: [fileHandlerMixin],
  data() {
    return {
      pageNumber: 1,
      showPageLoadingSpinner: false,
      tableFields: [
        { key: 'name', thStyle: { width: '25%' } },
        { key: 'itemName', label: 'Item', thStyle: { width: '25%' } },
        { key: 'caseName', label: 'Letter Case', thStyle: { width: '25%' } },
        { key: 'actions', thStyle: { width: '25%' } },
      ],
      showCreateDownloadablePackModal: false,
      createDownloadablePackModal_name: '',
      createDownloadablePackModal_nameState: null,
    };
  },
  created() {
    this.initialiseUI();
  },
  computed: {
    section() {
      return this.$store.state.cart.section;
    },
    taskType() {
      return this.$store.state.cart.taskType;
    },
    worksheets() {
      return this.$store.state.cart.worksheets;
    },
    isCartContextConfirmed() {
      return this.$store.state.cart.cart.isCartContextConfirmed;
    },
    isCartEmpty() {
      return this.$store.getters['cart/worksheetsInCartCount'] === 0;
    },
    totalWorksheetsCount() {
      return this.worksheets?.length;
    },
    itemsCountPerPage() {
      return this.$store.state.applicationSettings.itemsCountPerPage;
    },
    tableIsBusy() {
      return this.$store.state.cart.isLoading
      || this.$store.state.worksheets.isDownloadingFile;
    },
    isDownloadingInstructionsFile() {
      return this.$store.state.taskTypes.isDownloadingFile;
    },
    createDownloadablePackButtonIsDisabled() {
      return this.isCartEmpty;
    },
    removeAllButtonIsVisible() {
      return this.worksheets?.length > 0;
    },
  },
  methods: {
    ...mapActions('taskTypes', ['getTaskTypeInstructions']),
    ...mapActions('worksheets', ['getWorksheetDocument']),
    ...mapActions('cart', ['loadWorksheets', 'getSection', 'getTaskType', 'loadWorksheets', 'removeWorksheetFromCart', 'removeAllWorksheetsFromCart', 'clearCart']),
    ...mapActions('downloadablePacks', ['createDownloadablePack']),
    async initialiseUI() {
      this.showPageLoadingSpinner = true;

      if (this.isCartContextConfirmed) {
        try {
          await this.getSection(this.$store.state.cart.cart.sectionId);
          await this.getTaskType(this.$store.state.cart.cart.taskTypeId);
          await this.loadWorksheets();
        } catch (error) {
          this.handleHttpError(error);
        }
      }

      this.showPageLoadingSpinner = false;
    },
    backToCatalogueClick() {
      this.$router.push('/catalogue');
    },
    async downloadInstructionsFile() {
      await this.getTaskTypeInstructions(this.taskType.id)
        .then((response) => { this.openFile(response); })
        .catch((error) => { this.handleHttpError(error); });
    },
    async downloadWorksheetClick(worksheet) {
      await this.getWorksheetDocument(worksheet.id)
        .then((response) => { this.openFile(response); })
        .catch((error) => { this.handleHttpError(error); });
    },
    async removeWorksheet(worksheet) {
      await this.removeWorksheetFromCart(worksheet)
        .then(async () => {
          this.pageNumber = 1;
          await this.loadWorksheets();
        });
    },
    async removeAllWorksheetsClick() {
      await this.removeAllWorksheetsFromCart()
        .then(async () => {
          this.pageNumber = 1;
          await this.loadWorksheets();
        });
    },
    async createDownloadablePackClick() {
      this.showCreateDownloadablePackModal = true;
    },
    createDownloadablePackModalReset() {
      this.createDownloadablePackModal_name = '';
      this.createDownloadablePackModal_nameState = null;
    },
    createDownloadablePackModalHandleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.createDownloadablePackModalHandleSubmit();
    },
    createDownloadablePackModalHandleSubmit() {
      const formIsValid = this.$refs.createDownloadablePackModalForm.checkValidity();
      if (!formIsValid) {
        this.createDownloadablePackModal_nameState = false;
        return;
      }
      this.doCreateDownloadablePack(this.createDownloadablePackModal_name);
      this.showCreateDownloadablePackModal = false;
    },
    async doCreateDownloadablePack(downloadablePackName) {
      const downloadablePack = {
        name: downloadablePackName,
        taskTypeId: this.$store.state.cart.cart.taskTypeId,
        worksheetIds: this.$store.state.cart.cart.worksheetIdentifiers,
      };

      this.showPageLoadingSpinner = true;

      await this.createDownloadablePack(downloadablePack)
        .then(() => {
          this.$router.push('/mydownloadablepacks');
          this.clearCart();
        })
        .catch((error) => {
          this.handleHttpError(error);
        });

      this.showPageLoadingSpinner = false;
    },
  },
};
</script>
