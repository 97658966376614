<template>
  <div>
    <div class="mb-3">
      <h5>Manage Organisations - {{titleText}}</h5>
    </div>
    <div v-if="organisation">
      <b-form
        autocomplete="off"
        v-on:submit="formSubmit">
        <b-form-group label="Organisation Name:"
          label-cols-lg="2"
          content-cols-lg="10">
          <b-form-input
            v-model="organisation.name"
            placeholder="Enter name"
            required>
          </b-form-input>
        </b-form-group>
        <b-form-group v-if="!isNew" label="Organisation Disabled:"
          label-cols-lg="2"
          content-cols-lg="10">
          <b-form-checkbox
            v-model="organisation.isDisabled">
          </b-form-checkbox>
        </b-form-group>
        <b-button class="mr-2"
          title="Cancel"
          v-bind:disabled="organisationsIsLoading"
          v-on:click="cancelClick">
          <b-icon-x />
        </b-button>
        <b-button class="mr-2" type="submit" variant="primary"
          v-bind:title="submitText"
          v-bind:disabled="organisationsIsLoading">
          <span v-if="isNew"><b-icon-plus /></span>
          <span v-else><b-icon-check /></span>
          <span v-if="organisationsIsLoading"><b-spinner label="Spinning" small></b-spinner></span>
        </b-button>
      </b-form>
    </div>
  </div>
</template>

<script>

import { mapActions } from 'vuex';

export default {
  name: 'OrganisationDetails',
  props: {
    organisation: {},
    new: { type: Boolean },
  },
  created() {
    if (!this.organisation) {
      // If we don't have a organisation at this point, it's possible the page has been refreshed.
      // This will have cleared the props, so go back to the list page.
      this.$router.go(-1);
    }
  },
  computed: {
    isNew() {
      return this.new;
    },
    organisationsIsLoading() {
      return this.$store.state.organisations.isLoading;
    },
    titleText() {
      return this.new === true ? 'Add Organisation' : 'Edit Organisation';
    },
    submitText() {
      return this.new === true ? 'Add Organisation' : 'Update Organisation';
    },
  },
  methods: {
    ...mapActions('organisations', ['createOrganisation', 'updateOrganisation']),
    cancelClick() {
      // Go back to previous page without adding a new history entry:
      this.$router.go(-1);
    },
    async formSubmit(event) {
      event.preventDefault();

      if (this.new) {
        await this.createOrganisation(this.organisation)
          .then(() => { this.$router.go(-1); })
          .catch((error) => { this.handleHttpError(error); });
      } else {
        this.confirmUpdateOrganisation();
      }
    },
    confirmUpdateOrganisation() {
      // Warn if the update will disable the account:
      if (this.organisation.isDisabled) {
        this.$bvModal.msgBoxConfirm(
          "Saving the organisation with 'Is Disabled' checked will prevent all users from this organisation from logging into the system. Organisation Administrators will have their administrative privileges denied if they are currently logged in.", {
            title: 'Warning',
            okVariant: 'danger',
            centered: true,
            hideHeaderClose: false,
          },
        )
          .then((value) => {
            // 'value' is true ONLY if OK is clicked:
            if (value) {
              this.doUpdateOrganisation();
            }
          });
      } else {
        this.doUpdateOrganisation();
      }
    },
    async doUpdateOrganisation() {
      await this.updateOrganisation(this.organisation)
        .then(() => { this.$router.go(-1); })
        .catch((error) => { this.handleHttpError(error); });
    },
  },
};
</script>
