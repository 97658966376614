import axios from 'axios';
import qs from 'qs';

// Configure initial cart from localStorage if available so
// that cart survives page refreshes.
const initialState = {
  cart: JSON.parse(localStorage.getItem('cart')) || {
    worksheetIdentifiers: [],
    sectionId: null,
    taskTypeId: null,
    isCartContextConfirmed: false,
  },
};

export default {
  namespaced: true,
  state: {
    ...initialState,
    section: null,
    taskType: null,
    worksheets: null,
    isLoading: false,
  },
  mutations: {
    setSelectedSectionId(state, sectionId) {
      state.cart.sectionId = sectionId;
      localStorage.setItem('cart', JSON.stringify(state.cart));
    },
    setSelectedTaskTypeId(state, taskTypeId) {
      state.cart.taskTypeId = taskTypeId;
      localStorage.setItem('cart', JSON.stringify(state.cart));
    },
    setCartContextConfirmed(state) {
      state.cart.isCartContextConfirmed = true;
      localStorage.setItem('cart', JSON.stringify(state.cart));
    },
    addWorksheetIdentifier(state, worksheetId) {
      state.cart.worksheetIdentifiers.push(worksheetId);
      localStorage.setItem('cart', JSON.stringify(state.cart));
    },
    removeWorksheetIdentifier(state, worksheetId) {
      const index = state.cart.worksheetIdentifiers.indexOf(worksheetId);
      if (index > -1) {
        state.cart.worksheetIdentifiers.splice(index, 1);
      }
      localStorage.setItem('cart', JSON.stringify(state.cart));
    },
    setWorksheetIdentifiers(state, worksheetIdentifiers) {
      state.cart.worksheetIdentifiers = worksheetIdentifiers;
      localStorage.setItem('cart', JSON.stringify(state.cart));
    },
    setCartEmpty(state) {
      state.cart = {
        worksheetIdentifiers: [],
        sectionId: null,
        taskTypeId: null,
        isCartContextConfirmed: false,
      };
      localStorage.removeItem('cart');
    },
    setSection(state, section) {
      state.section = section;
    },
    setTaskType(state, taskType) {
      state.taskType = taskType;
    },
    setWorksheets(state, worksheets) {
      state.worksheets = worksheets;
    },
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
  },
  getters: {
    isWorksheetInCart: (state) => (worksheet) => {
      if (state.cart.worksheetIdentifiers.includes(worksheet.id)) {
        return true;
      }
      return false;
    },
    worksheetsInCartCount(state) {
      return state.cart.worksheetIdentifiers.length;
    },
  },
  actions: {
    changeSelectedSection(context, selectedSectionId) {
      context.commit('setSelectedSectionId', selectedSectionId);
    },
    changeSelectedTaskType(context, selectedTaskTypeId) {
      context.commit('setSelectedTaskTypeId', selectedTaskTypeId);
    },
    confirmCartContext(context) {
      context.commit('setCartContextConfirmed');
    },
    addWorksheetToCart(context, worksheet) {
      if (worksheet.taskTypeId !== context.state.cart.taskTypeId) {
        const error = {
          errorTitle: 'Add Worksheet to Selection failed',
          errorMessage: 'All Worksheets in your Selection must all belong to the same Task Type',
        };
        throw error;
      }
      context.commit('addWorksheetIdentifier', worksheet.id);
    },
    removeWorksheetFromCart(context, worksheet) {
      context.commit('removeWorksheetIdentifier', worksheet.id);
    },
    removeAllWorksheetsFromCart(context) {
      context.commit('setWorksheetIdentifiers', []);
    },
    clearCart(context) {
      context.commit('setCartEmpty');
    },
    getSection(context, sectionId) {
      context.commit('setIsLoading', true);
      return axios.get(`/api/sections/${sectionId}`)
        .then((result) => {
          context.commit('setSection', result.data.section);
        })
        .catch((error) => { throw error; })
        .finally(() => {
          context.commit('setIsLoading', false);
        });
    },
    getTaskType(context, taskTypeId) {
      context.commit('setIsLoading', true);
      return axios.get(`/api/tasktypes/${taskTypeId}`)
        .then((result) => {
          context.commit('setTaskType', result.data.taskType);
        })
        .catch((error) => { throw error; })
        .finally(() => {
          context.commit('setIsLoading', false);
        });
    },
    loadWorksheets(context) {
      context.commit('setIsLoading', true);
      return axios.get('/api/worksheets/lookup', {
        params: {
          ids: context.state.cart.worksheetIdentifiers,
        },
        paramsSerializer: (params) => qs.stringify(params),
      })
        .then((result) => {
          // Repopulate the state with verified worksheet Ids:
          context.commit('setWorksheets', result.data.worksheets);
          context.commit('setWorksheetIdentifiers', result.data.worksheets.map((w) => w.id));
        })
        .catch((error) => { throw error; })
        .finally(() => {
          context.commit('setIsLoading', false);
        });
    },
  },
};
