<template>
  <div>
    <div class="mb-3">
      <h5>Manage Users - {{titleText}}</h5>
    </div>
    <div class="text-center" v-if="isInitialisingUI">
      <b-spinner label="Spinning"></b-spinner>
    </div>
    <div v-else>
      <div v-if="user">
        <b-form
          autocomplete="off"
          v-on:submit="formSubmit">
          <AdminDropdown for="organisations"
            preventSelectDefaultItem
            v-on:dropdownItemChanged="selectedOrganisationChanged" />
          <b-form-group label="Email Address:"
            label-cols-lg="2"
            content-cols-lg="10">
            <b-form-input
              v-model="user.emailAddress"
              v-bind:disabled="!isNew"
              placeholder="Enter email address"
              required>
            </b-form-input>
          </b-form-group>
          <b-form-group label="First Name:"
            label-cols-lg="2"
            content-cols-lg="10">
            <b-form-input
              v-model="user.firstName"
              placeholder="Enter first name"
              required>
            </b-form-input>
          </b-form-group>
          <b-form-group label="Last Name:"
            label-cols-lg="2"
            content-cols-lg="10">
            <b-form-input
              v-model="user.lastName"
              placeholder="Enter last name"
              required>
            </b-form-input>
          </b-form-group>
          <b-form-group label="Organisation Administrator:"
            label-cols-lg="2"
            content-cols-lg="10">
            <b-form-checkbox
                id="checkbox-isorgadmin"
                v-model="user.isOrganisationAdministrator"
                name="checkbox-isorgadmin">
              </b-form-checkbox>
          </b-form-group>
          <b-form-group v-if="!isNew" label="Account Disabled:"
            label-cols-lg="2"
            content-cols-lg="10">
            <b-form-checkbox
              v-model="user.isDisabled">
            </b-form-checkbox>
          </b-form-group>
          <b-button class="mr-2"
            title="Cancel"
            v-bind:disabled="usersIsLoading"
            v-on:click="cancelClick">
            <b-icon-x />
          </b-button>
          <b-button class="mr-2" type="submit" variant="primary"
            v-bind:title="submitText"
            v-bind:disabled="usersIsLoading">
            <span v-if="isNew"><b-icon-plus /></span>
            <span v-else><b-icon-check /></span>
            <span v-if="usersIsLoading"><b-spinner label="Spinning" small></b-spinner></span>
          </b-button>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>

import { mapActions } from 'vuex';
import AdminDropdown from '../components/AdminDropdown.vue';

export default {
  name: 'UserDetails',
  components: { AdminDropdown },
  props: {
    user: {},
    new: { type: Boolean },
  },
  data() {
    return {
      isInitialisingUI: false,
    };
  },
  created() {
    if (!this.user) {
      this.$router.go(-1);
    } else {
      this.initialiseUI();
    }
  },
  computed: {
    isNew() {
      return this.new;
    },
    organisations() {
      return this.$store.state.organisations.organisations;
    },
    usersIsLoading() {
      return this.$store.state.users.isLoading;
    },
    titleText() {
      return this.new === true ? 'Add User' : 'Edit User';
    },
    submitText() {
      return this.new === true ? 'Add User' : 'Update User';
    },
  },
  methods: {
    ...mapActions('users', ['createUser', 'updateUser']),
    ...mapActions('organisations', ['getOrganisations']),
    async initialiseUI() {
      this.isInitialisingUI = true;

      try {
        await this.getOrganisations()
          .then(() => {
            this.$store.dispatch('organisations/changeSelectedOrganisation', this.user.organisationId);
          });

        if (this.isUserOrganisationAdministrator(this.user.roleId)) {
          this.user.isOrganisationAdministrator = true;
        } else {
          this.user.isOrganisationAdministrator = false;
        }
      } catch (error) {
        this.handleHttpError(error);
        this.$store.dispatch('organisations/clearState');
        this.$store.dispatch('users/clearState');
      }

      this.isInitialisingUI = false;
    },
    isUserOrganisationAdministrator(roleId) {
      return roleId === 2;
    },
    selectedOrganisationChanged() {
      this.user.organisationId = this.$store.state.organisations.selectedOrganisationId;
    },
    cancelClick() {
      this.$router.go(-1);
    },
    formSubmit(event) {
      event.preventDefault();

      if (this.new) {
        this.addUser();
      } else {
        this.confirmUpdateUser();
      }
    },
    addUser() {
      const messageDiv = this.$createElement('div', [
        this.$createElement('p', [
          this.$createElement('strong', 'Information: '),
          'This will create a new user and send a confirmation email to the specified email address.',
        ]),
        this.$createElement('p', [
          'The new user will need to confirm their account by following the link in the confirmation email before they can log in to the system.',
        ]),
        this.$createElement('p', [
          'Click OK to create the user and send the confirmation email.',
        ]),
      ]);

      this.$bvModal.msgBoxConfirm(
        messageDiv, {
          title: 'Create User',
          okVariant: 'primary',
          centered: true,
          hideHeaderClose: false,
        },
      )
        .then((value) => {
          if (value) {
            this.createUser(this.user)
              .then(() => {
                this.$router.go(-1);
              })
              .catch((error) => { this.handleHttpError(error); });
          }
        });
    },
    confirmUpdateUser() {
      if (this.user.isDisabled) {
        this.$bvModal.msgBoxConfirm(
          "Saving the user with 'Is Account Disabled' checked will prevent the user from logging into the system. If they are an Organisation Administrator, their administrative privileges will be denied if they are currently logged in.", {
            title: 'Warning',
            okVariant: 'danger',
            centered: true,
            hideHeaderClose: false,
          },
        )
          .then((value) => {
            if (value) {
              this.doUpdateUser();
            }
          });
      } else {
        this.doUpdateUser();
      }
    },
    doUpdateUser() {
      this.updateUser(this.user)
        .then(() => { this.$router.go(-1); })
        .catch((error) => { this.handleHttpError(error); });
    },
  },
};
</script>
