<template>
  <div>
    <div class="mb-3">
      <h5>Manage System Administrators - {{titleText}}</h5>
    </div>
    <div v-if="administrator">
      <b-form
        autocomplete="off"
        v-on:submit="formSubmit">
        <b-form-group label="Email Address:"
          label-cols-lg="2"
          content-cols-lg="10">
          <b-form-input
            v-model="administrator.emailAddress"
            v-bind:disabled="!isNew"
            placeholder="Enter email address"
            required>
          </b-form-input>
        </b-form-group>
        <b-form-group
          label-cols-lg="2"
          content-cols-lg="10"
          label="First Name:">
          <b-form-input
            v-model="administrator.firstName"
            placeholder="Enter first name"
            required>
          </b-form-input>
        </b-form-group>
        <b-form-group label="Last Name:"
          label-cols-lg="2"
          content-cols-lg="10">
          <b-form-input
            v-model="administrator.lastName"
            placeholder="Enter last name"
            required>
          </b-form-input>
        </b-form-group>
        <b-form-group v-if="!isNew" label="Account Disabled:"
          label-cols-lg="2"
          content-cols-lg="10">
          <b-form-checkbox
            v-model="administrator.isDisabled">
          </b-form-checkbox>
        </b-form-group>
        <b-button class="mr-2"
          title="Cancel"
          v-bind:disabled="administratorsIsLoading"
          v-on:click="cancelClick">
          <b-icon-x />
        </b-button>
        <b-button class="mr-2" type="submit" variant="primary"
          v-bind:title="submitText"
          v-bind:disabled="administratorsIsLoading">
          <span v-if="isNew"><b-icon-plus /></span>
          <span v-else><b-icon-check /></span>
          <span v-if="administratorsIsLoading"><b-spinner label="Spinning" small></b-spinner></span>
        </b-button>
      </b-form>
    </div>
  </div>
</template>

<script>

import { mapActions } from 'vuex';

export default {
  name: 'SystemAdministratorDetails',
  props: {
    administrator: {},
    new: { type: Boolean },
  },
  created() {
    if (!this.administrator) {
      this.$router.go(-1);
    }
  },
  computed: {
    isNew() {
      return this.new;
    },
    administratorsIsLoading() {
      return this.$store.state.systemAdministrators.isLoading;
    },
    titleText() {
      return this.new === true ? 'Add System Administrator' : 'Edit System Administrator';
    },
    submitText() {
      return this.new === true ? 'Add System Administrator' : 'Update System Administrator';
    },
  },
  methods: {
    ...mapActions('systemAdministrators', ['createSystemAdministrator', 'updateSystemAdministrator']),
    cancelClick() {
      this.$router.go(-1);
    },
    async formSubmit(event) {
      event.preventDefault();

      if (this.new) {
        this.addAdministrator();
      } else {
        this.confirmUpdateAdministrator();
      }
    },
    async addAdministrator() {
      const messageDiv = this.$createElement('div', [
        this.$createElement('p', [
          this.$createElement('strong', 'Information: '),
          'This will create a new System Administrator user and send a confirmation email to the specified email address.',
        ]),
        this.$createElement('p', [
          'The new System Administrator will need to confirm their account by following the link in the confirmation email before they can log in to the system.',
        ]),
        this.$createElement('p', [
          'Click OK to create the user and send the confirmation email.',
        ]),
      ]);

      this.$bvModal.msgBoxConfirm(
        messageDiv, {
          title: 'Create System Administrator',
          okVariant: 'primary',
          centered: true,
          hideHeaderClose: false,
        },
      )
        .then(async (value) => {
          if (value) {
            await this.createSystemAdministrator(this.administrator)
              .then(() => {
                this.$router.go(-1);
              })
              .catch((error) => { this.handleHttpError(error); });
          }
        });
    },
    async confirmUpdateAdministrator() {
      if (this.administrator.isDisabled) {
        this.$bvModal.msgBoxConfirm(
          "Saving the System Administrator with 'Is Account Disabled' checked will prevent the user from logging into the system. Their administrative privileges will be denied if they are currently logged in.", {
            title: 'Warning',
            okVariant: 'danger',
            centered: true,
            hideHeaderClose: false,
          },
        )
          .then((value) => {
            if (value) {
              this.updateAdministrator();
            }
          });
      } else {
        this.updateAdministrator();
      }
    },
    async updateAdministrator() {
      await this.updateSystemAdministrator(this.administrator)
        .then(() => { this.$router.go(-1); })
        .catch((error) => { this.handleHttpError(error); });
    },
  },
};
</script>
