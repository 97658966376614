<template>
  <div>
    <div class="mb-3">
      <h5>Manage Task Types</h5>
    </div>

    <div class="text-center" v-if="isInitialisingUI">
      <b-spinner label="Spinning"></b-spinner>
    </div>
    <div v-else>
      <b-card>
        <AdminDropdown for="sections"
          preventSelectDefaultItem
          v-on:dropdownItemChanged="selectedSectionChanged" />
      </b-card>
      <div>
        <b-table striped bordered hover
          stacked="md"
          v-bind:items="taskTypes"
          v-bind:fields="tableFields"
          v-bind:busy="tableIsBusy"
          show-empty
          empty-text="No data to show">
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle"></b-spinner>
            </div>
          </template>
          <template #cell(actions)="data">
            <b-button class="mr-2" size="sm" variant="info"
              title="Download Instructions"
              v-on:click="downloadInstructionsClick(data.item)">
              <b-icon-download />
            </b-button>
            <b-button class="mr-2" size="sm" variant="info"
              title="Edit"
              v-on:click="editTaskTypeClick(data.item)">
              <b-icon-pencil />
            </b-button>
            <b-button class="mr-2" size="sm" variant="danger"
              title="Delete"
              v-on:click="deleteTaskTypeClick(data.item)">
              <b-icon-x-circle />
            </b-button>
          </template>
        </b-table>
        <b-pagination
          v-model="pageNumber"
          v-bind:total-rows="totalTaskTypesCount"
          v-bind:per-page="itemsCountPerPage"
          v-bind:disabled="tableIsBusy"
          v-on:change="onPageChange"
          align="center">
        </b-pagination>
      </div>
      <div>
        <b-button variant="primary"
        v-bind:disabled="addButtonIsDisabled"
        v-on:click="addTaskTypeClick">
          Add Task Type <b-icon-plus-circle />
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import AdminDropdown from '../components/AdminDropdown.vue';
import fileHandlerMixin from '../mixins/fileHandlerMixin';

export default {
  name: 'TaskTypes',
  components: { AdminDropdown },
  mixins: [fileHandlerMixin],
  data() {
    return {
      pageNumber: 1,
      tableFields: [
        { key: 'name', thStyle: { width: '70%' } },
        { key: 'actions', thStyle: { width: '30%' } },
      ],
      isInitialisingUI: false,
    };
  },
  created() {
    this.initialiseUI();
  },
  computed: {
    sections() {
      return this.$store.state.sections.sections;
    },
    taskTypes() {
      return this.$store.state.taskTypes.taskTypes;
    },
    totalTaskTypesCount() {
      return this.$store.state.taskTypes.totalTaskTypesCount;
    },
    itemsCountPerPage() {
      return this.$store.state.applicationSettings.itemsCountPerPage;
    },
    tableIsBusy() {
      return this.$store.state.taskTypes.isLoading || this.$store.state.taskTypes.isDownloadingFile;
    },
    addButtonIsDisabled() {
      return this.$store.state.taskTypes.isLoading
      || this.$store.state.taskTypes.isDownloadingFile
      || this.$store.state.sections.selectedSectionId === null;
    },
    searchParams() {
      return {
        pageNumber: this.pageNumber,
        pageSize: this.itemsCountPerPage,
        sectionId: this.$store.state.sections.selectedSectionId,
      };
    },
  },
  methods: {
    ...mapActions('sections', ['getSections']),
    ...mapActions('taskTypes', ['searchTaskTypes', 'deleteTaskType', 'getTaskTypeInstructions']),
    async initialiseUI() {
      this.isInitialisingUI = true;

      try {
        await this.getSections();

        if (this.$store.state.sections.selectedSectionId) {
          await this.searchTaskTypes(this.searchParams);
        } else {
          this.$store.dispatch('taskTypes/clearState');
        }
      } catch (error) {
        this.handleHttpError(error);
        this.$store.dispatch('sections/clearState');
        this.$store.dispatch('taskTypes/clearState');
      }

      this.isInitialisingUI = false;
    },
    async onPageChange(value) {
      this.pageNumber = value;

      await this.searchTaskTypes(this.searchParams)
        .catch((error) => {
          this.handleHttpError(error);
          this.$store.dispatch('taskTypes/clearState');
        });
    },
    async selectedSectionChanged() {
      this.pageNumber = 1;
      await this.searchTaskTypes(this.searchParams)
        .catch((error) => {
          this.handleHttpError(error);
          this.$store.dispatch('taskTypes/clearState');
        });
    },
    addTaskTypeClick() {
      this.$router.push({
        name: 'Admin-TaskTypeDetails',
        params: {
          taskType: { id: 0, name: '', sectionId: this.$store.state.sections.selectedSectionId },
          new: true,
        },
      });
    },
    editTaskTypeClick(taskType) {
      this.$router.push({
        name: 'Admin-TaskTypeDetails',
        params: {
          taskType,
          new: false,
        },
      });
    },
    async downloadInstructionsClick(taskType) {
      await this.getTaskTypeInstructions(taskType.id)
        .then((response) => { this.openFile(response); })
        .catch((error) => { this.handleHttpError(error); });
    },
    async deleteTaskTypeClick(taskType) {
      this.$bvModal.msgBoxConfirm(
        `Are you sure you want to delete "${taskType.name}"?`, {
          title: 'Delete Task Type',
          okVariant: 'danger',
          centered: true,
          hideHeaderClose: false,
        },
      )
        .then(async (value) => {
          if (value) {
            await this.deleteTaskType(taskType.id)
              .then(async () => {
                this.pageNumber = 1;
                await this.searchTaskTypes(this.searchParams);
              })
              .catch((error) => { this.handleHttpError(error); });
          }
        });
    },
  },
};
</script>
