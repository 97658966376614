import axios from 'axios';

export default {
  namespaced: true,
  state: {
    environment: null,
    itemsCountPerPage: 0,
    isLoading: false,
  },
  mutations: {
    setEnvironment(state, environment) {
      state.environment = environment;
    },
    setItemsCountPerPage(state, itemsCountPerPage) {
      state.itemsCountPerPage = itemsCountPerPage;
    },
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
  },
  actions: {
    getApplicationSettings(context) {
      context.commit('setIsLoading', true);
      return axios.get('/api/applicationsettings')
        .then((result) => {
          context.commit('setEnvironment', result.data.environment);
          context.commit('setItemsCountPerPage', result.data.itemsCountPerPage);
        })
        .catch((error) => { throw error; })
        .finally(() => {
          context.commit('setIsLoading', false);
        });
    },
  },
};
