<!-- eslint-disable max-len -->
<template>
  <div class="text-center">
    <AphasiaTherapyResourcesLogo />
    <p>
      If you are already a registered user with us, please <strong>log in</strong> to access the resources.
    </p>
    <p>
      If you are new to these resources, they are designed for Speech and Language Therapists to use within therapy when working with people with aphasia.
      Please contact <a href="mailto:aphasia@ncl.ac.uk">aphasia@ncl.ac.uk</a> for further information.
    </p>
  </div>
</template>

<script>
import AphasiaTherapyResourcesLogo from './AphasiaTherapyResourcesLogo.vue';

export default {
  name: 'LoginForm',
  components: { AphasiaTherapyResourcesLogo },
};
</script>
