<template>
  <div>
    <h3 class="mb-3">Organisation User Details</h3>
    <div class="text-center" v-if="isInitialisingUI">
      <b-spinner label="Spinning"></b-spinner>
    </div>
    <div v-else>
      <div v-if="user">
        <b-form
          autocomplete="off"
          v-on:submit="formSubmit">
          <b-form-group label="Email Address:"
            label-cols-lg="2"
            content-cols-lg="10">
            <b-form-input
              v-model="user.emailAddress"
              v-bind:disabled="!isNew"
              placeholder="Enter email address"
              required>
            </b-form-input>
          </b-form-group>
          <b-form-group label="First Name:"
            label-cols-lg="2"
            content-cols-lg="10">
            <b-form-input
              v-model="user.firstName"
              placeholder="Enter first name"
              required>
            </b-form-input>
          </b-form-group>
          <b-form-group label="Last Name:"
            label-cols-lg="2"
            content-cols-lg="10">
            <b-form-input
              v-model="user.lastName"
              placeholder="Enter last name"
              required>
            </b-form-input>
          </b-form-group>
          <b-form-group label="Organisation Administrator:"
            label-cols-lg="2"
            content-cols-lg="10">
            <b-form-checkbox
                id="checkbox-isorgadmin"
                v-model="user.isOrganisationAdministrator"
                name="checkbox-isorgadmin">
              </b-form-checkbox>
          </b-form-group>
          <b-form-group v-if="!isNew" label="Account Disabled:"
            label-cols-lg="2"
            content-cols-lg="10">
            <b-form-checkbox
              v-model="user.isDisabled">
            </b-form-checkbox>
          </b-form-group>
          <b-button class="mr-2"
            title="Cancel"
            v-bind:disabled="usersIsLoading"
            v-on:click="cancelClick">
            <b-icon-x />
          </b-button>
          <b-button class="mr-2" type="submit" variant="primary"
            v-bind:title="submitText"
            v-bind:disabled="usersIsLoading">
            <span v-if="isNew"><b-icon-plus /></span>
            <span v-else><b-icon-check /></span>
            <span v-if="usersIsLoading"><b-spinner label="Spinning" small></b-spinner></span>
          </b-button>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>

import { mapActions } from 'vuex';

export default {
  name: 'OrganisationUserDetails',
  props: {
    user: {},
    new: { type: Boolean },
  },
  data() {
    return {
      isInitialisingUI: false,
    };
  },
  created() {
    if (!this.user) {
      // If we don't have a user at this point, it's possible the page has been refreshed.
      // This will have cleared the props, so go back to the list page.
      this.$router.go(-1);
    } else {
      this.initialiseUI();
    }
  },
  computed: {
    isNew() {
      return this.new;
    },
    usersIsLoading() {
      return this.$store.state.users.isLoading;
    },
    submitText() {
      return this.new === true ? 'Add User' : 'Update User';
    },
  },
  methods: {
    ...mapActions('users', ['createUser', 'updateUser']),
    async initialiseUI() {
      this.isInitialisingUI = true;

      // Set isOrganisationAdministrator based on user's roleId:
      if (this.isUserOrganisationAdministrator(this.user.roleId)) {
        this.user.isOrganisationAdministrator = true;
      } else {
        this.user.isOrganisationAdministrator = false;
      }

      // Set organisationId based on the logged-in user's organisation:
      this.user.organisationId = this.$store.state.authentication.user.organisationId;

      this.isInitialisingUI = false;
    },
    isUserOrganisationAdministrator(roleId) {
      // TODO: Remove magic number... replace with enum
      return roleId === 2;
    },
    selectedOrganisationChanged(value) {
      this.user.organisationId = value;
    },
    cancelClick() {
      // Go back to previous page without adding a new history entry:
      this.$router.go(-1);
    },
    formSubmit(event) {
      event.preventDefault();

      if (this.new) {
        this.addUser();
      } else {
        this.confirmUpdateUser();
      }
    },
    async addUser() {
      const messageDiv = this.$createElement('div', [
        this.$createElement('p', [
          this.$createElement('strong', 'Information: '),
          'This will create a new user and send a confirmation email to the specified email address.',
        ]),
        this.$createElement('p', [
          'The new user will need to confirm their account by following the link in the confirmation email before they can log in to the system.',
        ]),
        this.$createElement('p', [
          'Click OK to create the user and send the confirmation email.',
        ]),
      ]);

      this.$bvModal.msgBoxConfirm(
        messageDiv, {
          title: 'Create User',
          okVariant: 'primary',
          centered: true,
          hideHeaderClose: false,
        },
      )
        .then(async (value) => {
          // 'value' is true ONLY if OK is clicked:
          if (value) {
            await this.createUser(this.user)
              .then(() => {
                this.$router.go(-1);
              })
              .catch((error) => { this.handleHttpError(error); });
          }
        });
    },
    confirmUpdateUser() {
      // Warn if the update will disable the account:
      if (this.user.isDisabled) {
        this.$bvModal.msgBoxConfirm(
          "Saving the user with 'Is Account Disabled' checked will prevent the user from logging into the system. If they are an Organisation Administrator, their administrative privileges will be denied if they are currently logged in.", {
            title: 'Warning',
            okVariant: 'danger',
            centered: true,
            hideHeaderClose: false,
          },
        )
          .then((value) => {
            // 'value' is true ONLY if OK is clicked:
            if (value) {
              this.doUpdateUser();
            }
          });
      } else {
        this.doUpdateUser();
      }
    },
    async doUpdateUser() {
      await this.updateUser(this.user)
        .then(() => { this.$router.go(-1); })
        .catch((error) => { this.handleHttpError(error); });
    },
  },
};
</script>
