<template>
  <div>
    <div class="mb-3">
      <h5>Manage Items - {{titleText}}</h5>
    </div>
    <div class="text-center" v-if="isInitialisingUI">
      <b-spinner label="Spinning"></b-spinner>
    </div>
    <div v-else>
      <div v-if="item">
        <b-form
          autocomplete="off"
          v-on:submit="formSubmit">
          <AdminDropdown for="sections"
            preventSelectDefaultItem
            v-on:dropdownItemChanged="selectedSectionChanged" />
          <AdminDropdown for="wordcategories"
            preventSelectDefaultItem
            v-on:dropdownItemChanged="selectedWordCategoryChanged" />
          <b-form-group label="Item Name:"
            label-cols-lg="2"
            content-cols-lg="10">
            <b-form-input
              v-model="item.name"
              placeholder="Enter name"
              required>
            </b-form-input>
          </b-form-group>
          <b-button class="mr-2"
            title="Cancel"
            v-bind:disabled="itemsIsLoading"
            v-on:click="cancelClick">
            <b-icon-x />
          </b-button>
          <b-button class="mr-2" type="submit" variant="primary"
            v-bind:title="submitText"
            v-bind:disabled="itemsIsLoading">
            <span v-if="isNew"><b-icon-plus /></span>
            <span v-else><b-icon-check /></span>
            <span v-if="itemsIsLoading"><b-spinner label="Spinning" small></b-spinner></span>
          </b-button>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>

import { mapActions } from 'vuex';
import AdminDropdown from '../components/AdminDropdown.vue';

export default {
  name: 'ItemDetails',
  components: { AdminDropdown },
  data() {
    return {
      isInitialisingUI: false,
    };
  },
  props: {
    item: {},
    new: { type: Boolean },
  },
  created() {
    if (!this.item) {
      this.$router.go(-1);
    } else {
      this.initialiseUI();
    }
  },
  computed: {
    isNew() {
      return this.new;
    },
    sections() {
      return this.$store.state.sections.sections;
    },
    wordCategories() {
      return this.$store.state.wordCategories.wordCategories;
    },
    itemsIsLoading() {
      return this.$store.state.items.isLoading;
    },
    wordCategoriesDropDownIsDisabled() {
      return this.$store.state.sections.selectedSectionId === null
      || this.$store.state.sections.isLoading
      || this.$store.state.wordCategories.isLoading;
    },
    titleText() {
      return this.new === true ? 'Add Item' : 'Edit Item';
    },
    submitText() {
      return this.new === true ? 'Add Item' : 'Update Item';
    },
  },
  methods: {
    ...mapActions('sections', ['getSections']),
    ...mapActions('wordCategories', ['searchWordCategories']),
    ...mapActions('items', ['createItem', 'updateItem']),
    async initialiseUI() {
      this.isInitialisingUI = true;

      try {
        await this.getSections()
          .then(() => {
            this.$store.dispatch('sections/changeSelectedSection', this.item.sectionId);
          });

        if (this.$store.state.sections.selectedSectionId) {
          await this.searchWordCategories({
            sectionId: this.$store.state.sections.selectedSectionId,
          })
            .then(() => {
              this.$store.dispatch('wordCategories/changeSelectedWordCategory', this.item.wordCategoryId);
            });
        }
      } catch (error) {
        this.handleHttpError(error);
        this.$store.dispatch('sections/clearState');
        this.$store.dispatch('wordCategories/clearState');
        this.$store.dispatch('items/clearState');
      }
      this.isInitialisingUI = false;
    },
    async selectedSectionChanged() {
      this.item.sectionId = this.$store.state.sections.selectedSectionId;
      this.item.wordCategoryId = null;

      await this.searchWordCategories({
        sectionId: this.$store.state.sections.selectedSectionId,
      })
        .catch((error) => {
          this.handleHttpError(error);
          this.$store.dispatch('wordCategories/clearState');
          this.$store.dispatch('items/clearState');
        });
    },
    selectedWordCategoryChanged() {
      this.item.wordCategoryId = this.$store.state.wordCategories.selectedWordCategoryId;
    },
    cancelClick() {
      this.$router.go(-1);
    },
    async formSubmit(event) {
      event.preventDefault();

      if (this.new) {
        await this.createItem(this.item)
          .then(() => { this.$router.go(-1); })
          .catch((error) => { this.handleHttpError(error); });
      } else {
        await this.updateItem(this.item)
          .then(() => { this.$router.go(-1); })
          .catch((error) => { this.handleHttpError(error); });
      }
    },
  },
};
</script>
