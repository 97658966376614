<template>
  <div>
    <h3>Browse Catalogue</h3>

    <div class="text-center" v-if="isInitialisingUI">
      <b-spinner label="Spinning"></b-spinner>
    </div>
    <div v-else>
      <div ref="context">
        <b-card>
          <b-card-text>
            Set the context for your selection:
          </b-card-text>
          <b-form v-on:submit="onSetCartContext">
            <CatalogueDropdown for="sections" preventSelectDefaultItem
               v-on:dropdownItemChanged="selectedSectionChanged" />

            <CatalogueDropdown for="tasktypes" preventSelectDefaultItem
               v-on:dropdownItemChanged="selectedTaskTypeChanged" />
            <b-form-text v-if="downloadTaskTypeLinkIsVisible">
              <b-link v-on:click="downloadInstructionsFile">
                Download Instructions Document
                <b-spinner v-if="isDownloadingInstructionsFile" label="Spinning" small />
                <b-icon-download v-else />
              </b-link>
            </b-form-text>

            <div class="text-center">
              <b-button type="submit"
                variant="primary"
                v-bind:disabled="setButtonIsDisabled"
                v-if="setButtonIsVisible">
                <span v-if="!filtersAreLoading">Set</span>
                <b-spinner v-else label="Spinning" small />
              </b-button>
              <b-button type="submit"
                variant="secondary"
                v-if="changeButtonIsVisible"
                v-on:click="onChangeButtonClick">
                Change
              </b-button>
            </div>
          </b-form>
        </b-card>
      </div>
      <div ref="search" v-if="isCartContextConfirmed">
        <b-card>
          <b-card-text>
            Search for your worksheets:
          </b-card-text>
          <b-form v-on:submit="onSearch">
            <b-form-group id="input-group-search"
              label-cols-lg="2"
              content-cols-lg="10"
              label="Item Name:" label-for="input-search">
              <b-form-input id="input-search"
                type="text"
                autocomplete="off"
                placeholder="Search item name (optional)"
                v-model="itemSearchTerm"
                v-bind:disabled="itemSearchTermIsDisabled">
              </b-form-input>
            </b-form-group>

            <CatalogueDropdown for="itemfirstletters" />

            <CatalogueDropdown for="itemwordlengths" />

            <CatalogueDropdown for="wordcategories"
               v-on:dropdownItemChanged="selectedWordCategoryChanged" />

            <CatalogueDropdown for="items" />

            <CatalogueDropdown for="lettercases" />

            <b-form-group id="input-group-clearfilters"
              label-cols-lg="2"
              content-cols-lg="10">
              <b-button size="sm"
                v-on:click="onClearFiltersClick"
                v-bind:disabled="clearFiltersButtonIsDisabled">
                Clear filters
              </b-button>
            </b-form-group>
            <div class="text-center">
              <b-button type="submit"
                variant="primary"
                v-bind:disabled="searchButtonIsDisabled">
                <span v-if="!resultsAreLoading">Search</span>
                <b-spinner v-else label="Spinning" small />
              </b-button>
            </div>
          </b-form>
        </b-card>
        <div ref="results" v-if="worksheets">
          <div class="text-right mt-2 mb-2">
            <b-button size="sm"
              v-bind:variant="worksheetsInCartCount === 0 ? 'outline-success' : 'success'"
              v-bind:to="{name: 'Cart'}"
              title="Your Selected Worksheets">
              <b-icon-cart v-if="worksheetsInCartCount === 0" />
              <b-icon-cart-fill v-else />
              {{worksheetsInCartCount}}
            </b-button>
          </div>
          <b-table striped bordered hover
            stacked="lg"
            v-bind:items="worksheets"
            v-bind:fields="tableFields"
            v-bind:busy="tableIsBusy"
            show-empty
            empty-text="No data to show">
            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle"></b-spinner>
              </div>
            </template>
            <template #cell(actions)="data">
              <b-button class="mr-2" size="sm" variant="info"
                title="Download Worksheet"
                v-on:click="downloadWorksheetClick(data.item)">
                <b-icon-download />
              </b-button>
              <b-button v-if="!isWorksheetInCart(data.item)"
                class="mr-2" size="sm" variant="success"
                title="Add to Selection"
                v-on:click="addToCartClick(data.item)">
                <b-icon-cart-plus />
              </b-button>
              <b-button v-if="isWorksheetInCart(data.item)"
                class="mr-2" size="sm" variant="danger"
                title="Remove from Selection"
                v-on:click="removeFromCartClick(data.item)">
                <b-icon-cart-dash />
              </b-button>
            </template>
          </b-table>
          <b-pagination
            v-model="pageNumber"
            v-bind:total-rows="totalWorksheetsCount"
            v-bind:per-page="itemsCountPerPage"
            v-bind:disabled="tableIsBusy"
            v-on:change="onPageChange"
            align="center">
          </b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CatalogueDropdown from '../components/CatalogueDropdown.vue';
import fileHandlerMixin from '../mixins/fileHandlerMixin';

export default {
  name: 'Catalogue',
  components: { CatalogueDropdown },
  mixins: [fileHandlerMixin],
  data() {
    return {
      pageNumber: 1,
      tableFields: [
        { key: 'name', thStyle: { width: '20%' } },
        { key: 'caseName', label: 'Letter Case', thStyle: { width: '20%' } },
        { key: 'itemName', label: 'Item', thStyle: { width: '20%' } },
        { key: 'taskTypeName', label: 'Task Type', thStyle: { width: '20%' } },
        { key: 'actions', thStyle: { width: '20%' } },
      ],
      isInitialisingUI: false,
      filtersAreLoading: false,
      resultsAreLoading: false,
    };
  },
  created() {
    this.initialiseUI();
  },
  computed: {
    ...mapGetters('cart', ['isWorksheetInCart', 'worksheetsInCartCount']),
    isCartContextConfirmed() {
      return this.$store.state.cart.cart.isCartContextConfirmed;
    },
    sections() {
      return this.$store.state.sections.sections;
    },
    taskTypes() {
      return this.$store.state.taskTypes.taskTypes;
    },
    wordCategories() {
      return this.$store.state.wordCategories.wordCategories;
    },
    items() {
      return this.$store.state.items.items;
    },
    cases() {
      return this.$store.state.cases.cases;
    },
    worksheets() {
      return this.$store.state.worksheets.worksheets;
    },
    itemSearchTerm: {
      get() {
        return this.$store.state.catalogue.itemSearchTerm;
      },
      set(newValue) {
        this.$store.dispatch('catalogue/changeItemSearchTerm', newValue);
      },
    },
    totalWorksheetsCount() {
      return this.$store.state.worksheets.totalWorksheetsCount;
    },
    itemsCountPerPage() {
      return this.$store.state.applicationSettings.itemsCountPerPage;
    },
    tableIsBusy() {
      return this.$store.state.worksheets.isLoading
      || this.$store.state.worksheets.isDownloadingFile;
    },
    downloadTaskTypeLinkIsVisible() {
      return this.$store.state.cart.cart.taskTypeId;
    },
    setButtonIsDisabled() {
      return this.$store.state.cart.cart.sectionId === null
      || this.$store.state.cart.cart.taskTypeId === null;
    },
    setButtonIsVisible() {
      return !this.isCartContextConfirmed;
    },
    changeButtonIsVisible() {
      return this.isCartContextConfirmed;
    },
    itemSearchTermIsDisabled() {
      return !this.isCartContextConfirmed;
    },
    clearFiltersButtonIsDisabled() {
      return !this.isCartContextConfirmed;
    },
    searchButtonIsDisabled() {
      return !this.isCartContextConfirmed
      || this.wordLengthIsInvalid;
    },
    isDownloadingInstructionsFile() {
      return this.$store.state.taskTypes.isDownloadingFile;
    },
    searchWorksheetsParams() {
      return {
        pageNumber: this.pageNumber,
        pageSize: this.itemsCountPerPage,
        sectionId: this.$store.state.cart.cart.sectionId,
        taskTypeId: this.$store.state.cart.cart.taskTypeId,
        itemSearchTerm: this.itemSearchTerm,
        itemFirstLetter: this.$store.state.catalogue.selectedItemFirstLetter,
        itemWordLength: this.$store.state.catalogue.selectedItemWordLength,
        wordCategoryId: this.$store.state.catalogue.selectedWordCategoryId,
        itemId: this.$store.state.catalogue.selectedItemId,
        caseId: this.$store.state.catalogue.selectedCaseId,
      };
    },
  },
  methods: {
    ...mapActions('sections', ['getSections']),
    ...mapActions('taskTypes', ['searchTaskTypes', 'getTaskTypeInstructions']),
    ...mapActions('wordCategories', ['searchWordCategories']),
    ...mapActions('items', ['searchItems']),
    ...mapActions('cases', ['getCases']),
    ...mapActions('worksheets', ['searchWorksheets', 'getWorksheetDocument']),
    ...mapActions('cart', ['addWorksheetToCart', 'removeWorksheetFromCart']),
    async initialiseUI() {
      this.isInitialisingUI = true;

      try {
        await this.getSections();

        if (this.$store.state.cart.cart.sectionId) {
          await this.searchTaskTypes({
            sectionId: this.$store.state.cart.cart.sectionId,
          });
        } else {
          this.$store.dispatch('taskTypes/clearState');
        }

        if (this.isCartContextConfirmed) {
          await this.searchWordCategories({
            sectionId: this.$store.state.cart.cart.sectionId,
          });

          if (this.$store.state.catalogue.selectedWordCategoryId) {
            await this.searchItems({
              wordCategoryId: this.$store.state.catalogue.selectedWordCategoryId,
            });
          } else {
            await this.searchItems({
              sectionId: this.$store.state.cart.cart.sectionId,
            });
          }

          await this.getCases();

          await this.searchWorksheets(this.searchWorksheetsParams);
        } else {
          this.$store.dispatch('wordCategories/clearState');
          this.$store.dispatch('items/clearState');
          this.$store.dispatch('cases/clearState');
          this.$store.dispatch('worksheets/clearState');
        }
      } catch (error) {
        this.handleHttpError(error);
        this.$store.dispatch('sections/clearState');
        this.$store.dispatch('wordCategories/clearState');
        this.$store.dispatch('items/clearState');
        this.$store.dispatch('taskTypes/clearState');
        this.$store.dispatch('cases/clearState');
        this.$store.dispatch('worksheets/clearState');
      }

      this.isInitialisingUI = false;
      this.scrollTo('results');
    },
    async onPageChange(value) {
      this.pageNumber = value;

      await this.searchWorksheets(this.searchWorksheetsParams)
        .then(() => {
          this.scrollTo('results');
        })
        .catch((error) => {
          this.handleHttpError(error);
          this.$store.dispatch('worksheets/clearState');
        });
    },
    async selectedSectionChanged() {
      this.itemSearchTerm = null;

      try {
        await this.searchTaskTypes({
          sectionId: this.$store.state.cart.cart.sectionId,
        });
      } catch (error) {
        this.handleHttpError(error);
        this.$store.dispatch('taskTypes/clearState');
      }

      this.$store.dispatch('worksheets/clearState');
    },
    selectedTaskTypeChanged() {
      this.itemSearchTerm = null;

      this.$store.dispatch('worksheets/clearState');
    },
    async selectedWordCategoryChanged() {
      try {
        if (this.$store.state.catalogue.selectedWordCategoryId) {
          await this.searchItems({
            wordCategoryId: this.$store.state.catalogue.selectedWordCategoryId,
          });
        } else {
          await this.searchItems({
            sectionId: this.$store.state.cart.cart.sectionId,
          });
        }
      } catch (error) {
        this.handleHttpError(error);
        this.$store.dispatch('items/clearState');
      }
    },
    onClearFiltersClick() {
      this.itemSearchTerm = null;
      this.$store.dispatch('catalogue/changeSelectedItemFirstLetter', null);
      this.$store.dispatch('catalogue/changeSelectedItemWordLength', null);

      this.$store.dispatch('catalogue/changeSelectedWordCategory', null);
      this.$store.dispatch('catalogue/changeSelectedItem', null);
      this.$store.dispatch('catalogue/changeSelectedCase', null);
    },
    async onSetCartContext(event) {
      event.preventDefault();
      this.filtersAreLoading = true;
      try {
        await this.searchWordCategories({
          sectionId: this.$store.state.cart.cart.sectionId,
        });
        await this.searchItems({
          sectionId: this.$store.state.cart.cart.sectionId,
        });
        await this.getCases();

        this.$store.dispatch('cart/confirmCartContext');
        this.scrollTo('search');
      } catch (error) {
        this.handleHttpError(error);
        this.$store.dispatch('wordCategories/clearState');
        this.$store.dispatch('items/clearState');
        this.$store.dispatch('cases/clearState');
      } finally {
        this.filtersAreLoading = false;
      }
    },
    onChangeButtonClick(event) {
      event.preventDefault();

      if (this.worksheetsInCartCount > 0) {
        const messageDiv = this.$createElement('div', [
          this.$createElement('p', [
            this.$createElement('strong', 'Warning: '),
            'Changing your selection context will remove all worksheets from your selection.',
          ]),
          this.$createElement('p', [
            'Are you sure you want to change your selection context?',
          ]),
        ]);

        this.$bvModal.msgBoxConfirm(
          messageDiv, {
            title: 'Change selection context?',
            okVariant: 'danger',
            centered: true,
            hideHeaderClose: false,
          },
        )
          .then((value) => {
            if (value) {
              this.doClearContext();
            }
          });
      } else {
        this.doClearContext();
      }
    },
    doClearContext() {
      this.$store.dispatch('cart/clearCart');

      this.itemSearchTerm = null;
      this.$store.dispatch('catalogue/changeSelectedItemFirstLetter', null);
      this.$store.dispatch('catalogue/changeSelectedItemWordLength', null);

      this.$store.dispatch('catalogue/changeSelectedWordCategory', null);
      this.$store.dispatch('catalogue/changeSelectedItem', null);
      this.$store.dispatch('catalogue/changeSelectedCase', null);

      this.$store.dispatch('worksheets/clearState');
    },
    async onSearch(event) {
      event.preventDefault();
      this.resultsAreLoading = true;
      this.pageNumber = 1;
      await this.searchWorksheets(this.searchWorksheetsParams)
        .then(() => {
          this.scrollTo('results');
        })
        .catch((error) => {
          this.handleHttpError(error);
          this.$store.dispatch('worksheets/clearState');
        });
      this.resultsAreLoading = false;
    },
    async downloadInstructionsFile() {
      await this.getTaskTypeInstructions(this.$store.state.cart.cart.taskTypeId)
        .then((response) => { this.openFile(response); })
        .catch((error) => { this.handleHttpError(error); });
    },
    async downloadWorksheetClick(worksheet) {
      await this.getWorksheetDocument(worksheet.id)
        .then((response) => { this.openFile(response); })
        .catch((error) => { this.handleHttpError(error); });
    },
    addToCartClick(worksheet) {
      try {
        this.addWorksheetToCart(worksheet);
      } catch (error) {
        this.handleClientError(error);
      }
    },
    removeFromCartClick(worksheet) {
      this.removeWorksheetFromCart(worksheet);
    },
    async scrollTo(elementName) {
      // Allow a tick for any UI visibility updates, then scroll to requested element if it exists:
      await this.$nextTick();
      const element = this.$refs[elementName];
      if (element) {
        element.scrollIntoView({
          block: 'start',
          behavior: 'smooth',
        });
      }
    },
  },
};
</script>
