<template>
  <div>
    <h3 class="mb-3">Manage Organisation Users</h3>

    <div class="text-center" v-if="isInitialisingUI">
      <b-spinner label="Spinning"></b-spinner>
    </div>
    <div v-else>
      <div>
        <b-table striped bordered hover
          stacked="xl"
          v-bind:items="users"
          v-bind:fields="tableFields"
          v-bind:busy="tableIsBusy"
          show-empty
          empty-text="No data to show">
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle"></b-spinner>
            </div>
          </template>
          <template #cell(roleId)="data">
            <span v-if="isUserOrganisationAdministrator(data.item.roleId)">Yes</span>
            <span v-else>No</span>
          </template>
          <template #cell(isEmailConfirmed)="data">
            <span v-if="data.item.isEmailConfirmed">Yes</span>
            <span v-else>No</span>
          </template>
          <template #cell(isDisabled)="data">
            <span v-if="data.item.isDisabled">Yes</span>
            <span v-else>No</span>
          </template>
          <template #cell(actions)="data">
            <b-button class="mr-2" size="sm" variant="info"
              title="Re-send confirmation email"
              v-bind:disabled="!data.item.isEditable || data.item.isEmailConfirmed"
              v-on:click="reconfirmUserClick(data.item)">
              <b-icon-envelope />
            </b-button>
            <b-button class="mr-2" size="sm" variant="info"
              title="Edit"
              v-bind:disabled="!data.item.isEditable"
              v-on:click="editUserClick(data.item)">
              <b-icon-pencil />
            </b-button>
            <b-button class="mr-2" size="sm" variant="danger"
              title="Delete"
              v-bind:disabled="!data.item.isEditable"
              v-on:click="deleteUserClick(data.item)">
              <b-icon-x-circle />
            </b-button>
          </template>
        </b-table>
        <b-pagination
          v-model="pageNumber"
          v-bind:total-rows="totalUsersCount"
          v-bind:per-page="itemsCountPerPage"
          v-bind:disabled="tableIsBusy"
          v-on:change="onPageChange"
          align="center">
        </b-pagination>
      </div>
      <div>
        <b-button variant="primary"
        v-bind:disabled="addButtonIsDisabled"
        v-on:click="addUserClick">
          Add User <b-icon-plus-circle />
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'OrganisationUsers',
  data() {
    return {
      pageNumber: 1,
      tableFields: [
        { key: 'firstName', label: 'First Name', thStyle: { width: '15%' } },
        { key: 'lastName', label: 'Last Name', thStyle: { width: '15%' } },
        { key: 'emailAddress', thStyle: { width: '20%' } },
        { key: 'roleId', label: 'Is Organisation Administrator?', thStyle: { width: '10%' } },
        { key: 'isEmailConfirmed', label: 'Email Confirmed', thStyle: { width: '10%' } },
        { key: 'isDisabled', label: 'Account Disabled?', thStyle: { width: '10%' } },
        { key: 'actions', thStyle: { width: '15%' } },
      ],
      isInitialisingUI: false,
    };
  },
  created() {
    this.initialiseUI();
  },
  computed: {
    users() {
      return this.$store.state.users.users;
    },
    totalUsersCount() {
      return this.$store.state.users.totalUsersCount;
    },
    itemsCountPerPage() {
      return this.$store.state.applicationSettings.itemsCountPerPage;
    },
    tableIsBusy() {
      return this.$store.state.users.isLoading;
    },
    addButtonIsDisabled() {
      return this.$store.state.users.isLoading
      || this.selectedOrganisation === null;
    },
    searchParams() {
      return {
        pageNumber: this.pageNumber,
        pageSize: this.itemsCountPerPage,
      };
    },
  },
  methods: {
    ...mapActions('users', ['searchMyOrganisationUsers', 'deleteUser']),
    ...mapActions('authentication', ['requestUserConfirmation']),
    async initialiseUI() {
      this.isInitialisingUI = true;

      await this.searchMyOrganisationUsers(this.searchParams)
        .catch((error) => {
          this.handleHttpError(error);
          this.$store.dispatch('users/clearState');
        });

      this.isInitialisingUI = false;
    },
    onPageChange(value) {
      this.pageNumber = value;
      this.getUsersForOrganisation();
    },
    async getUsersForOrganisation() {
      await this.searchMyOrganisationUsers(this.searchParams)
        .catch((error) => {
          this.handleHttpError(error);
          this.$store.dispatch('users/clearState');
        });
    },
    isUserOrganisationAdministrator(roleId) {
      // TODO: Remove magic number... replace with enum
      return roleId === 2;
    },
    async reconfirmUserClick(user) {
      const messageDiv = this.$createElement('div', [
        this.$createElement('p', [
          this.$createElement('strong', 'Information: '),
          "This will re-send the account confirmation email to the user's email address.",
        ]),
        this.$createElement('p', [
          'Click OK to re-send the confirmation email.',
        ]),
      ]);

      const request = {
        userId: user.id,
      };

      this.$bvModal.msgBoxConfirm(
        messageDiv, {
          title: 'Re-send confirmation',
          okVariant: 'primary',
          centered: true,
          hideHeaderClose: false,
        },
      )
        .then(async (value) => {
          if (value) {
            await this.requestUserConfirmation(request)
              .catch((error) => { this.handleHttpError(error); });
          }
        });
    },
    addUserClick() {
      this.$router.push({
        name: 'OrganisationAdmin-UserDetails',
        params: {
          user: {
            id: 0,
            firstName: '',
            lastName: '',
            roleId: null,
          },
          new: true,
        },
      });
    },
    editUserClick(user) {
      this.$router.push({
        name: 'OrganisationAdmin-UserDetails',
        params: {
          user,
          new: false,
        },
      });
    },
    async deleteUserClick(user) {
      const messageDiv = this.$createElement('div', [
        this.$createElement('p', [
          this.$createElement('strong', 'Warning: '),
          'Deleting a user will remove all their access and all their associated data. This operation is permanent.',
        ]),
        this.$createElement('p', [
          `Are you sure you want to delete "${user.firstName} ${user.lastName}"?`,
        ]),
      ]);

      this.$bvModal.msgBoxConfirm(
        messageDiv, {
          title: 'Delete User',
          okVariant: 'danger',
          centered: true,
          hideHeaderClose: false,
        },
      )
        .then(async (value) => {
          // 'value' is true ONLY if OK is clicked:
          if (value) {
            await this.deleteUser(user.id)
              .then(async () => { await this.getUsersForOrganisation(); })
              .catch((error) => { this.handleHttpError(error); });
          }
        });
    },
  },
};
</script>
