import Vue from 'vue';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import axios from 'axios';
import App from './App.vue';
import router from './router';
import errorHandlerMixin from './mixins/errorHandlerMixin';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import store from './store';

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.config.productionTip = false;

// Register global error handler mixin:
Vue.mixin(errorHandlerMixin);

// Wire up axios interceptor for handling access token expiry:
axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401
      && error.response.headers['token-expired']
      && !originalRequest.retry) {
      originalRequest.retry = true;

      // Attempt to refresh the access token, then retry the request:
      await store.dispatch('authentication/refreshTokens')
        .catch(() => Promise.reject(error));

      return axios(originalRequest);
    }
    return Promise.reject(error);
  },
);

function startVue() {
  new Vue({
    router,
    render: (h) => h(App),
    store,
  }).$mount('#app');
}

// Get application settings before starting the Vue instance
// so they're available for components that need them:
store.dispatch('applicationSettings/getApplicationSettings')
  .then(() => {
    startVue();
  });
