<template>
  <div>
    <div class="mb-3">
      <h5>Configuration settings for this environment</h5>
    </div>
    <div class="text-center" v-if="isInitialisingUI">
      <b-spinner label="Spinning"></b-spinner>
    </div>
    <div v-else>
      <b-table-simple hover small caption-top fixed striped stacked>
        <b-tbody>
          <b-th colspan="2" class="text-center">ApplicationSettings</b-th>
          <b-tr v-for="(value, key) in applicationSettings">
            <b-th :stacked-heading="key">{{value}}</b-th>
          </b-tr>
          <b-th rowspan="3" class="text-center">ConnectionStrings</b-th>
          <b-tr>
            <b-th stacked-heading="AphasiaSqlConnection">{{systemConfig.aphasiaSqlConnection}}</b-th>
          </b-tr>
          <b-th rowspan="3" class="text-center">FileStorageSettings</b-th>
          <b-tr v-for="(value, key) in systemConfig.fileStorageSettings">
            <b-th :stacked-heading="key">{{value}}</b-th>
          </b-tr>
          <b-th rowspan="3" class="text-center">EmailSettings</b-th>
          <b-tr v-for="(value, key) in systemConfig.emailSettings">
            <b-th :stacked-heading="key">{{value}}</b-th>
          </b-tr>
          <b-th rowspan="3" class="text-center">FunctionsSettings</b-th>
          <b-tr v-for="(value, key) in systemConfig.functionsSettings">
            <b-th :stacked-heading="key">{{value}}</b-th>
          </b-tr>
          <b-th rowspan="3" class="text-center">JwtSettings</b-th>
          <b-tr v-for="(value, key) in systemConfig.jwtSettings">
            <b-th :stacked-heading="key">{{value}}</b-th>
          </b-tr>
          <b-th rowspan="3" class="text-center">AccountConfirmationLifespanSettings</b-th>
          <b-tr v-for="(value, key) in systemConfig.accountConfirmationLifespanSettings">
            <b-th :stacked-heading="key">{{value}}</b-th>
          </b-tr>
          <b-th rowspan="3" class="text-center">AccountConfirmationTokenLifespanSettings</b-th>
          <b-tr v-for="(value, key) in systemConfig.accountConfirmationTokenLifespanSettings">
            <b-th :stacked-heading="key">{{value}}</b-th>
          </b-tr>
          <b-th rowspan="3" class="text-center">PasswordResetLifespanSettings</b-th>
          <b-tr v-for="(value, key) in systemConfig.passwordResetLifespanSettings">
            <b-th :stacked-heading="key">{{value}}</b-th>
          </b-tr>
          <b-th rowspan="3" class="text-center">PasswordResetTokenLifespanSettings</b-th>
          <b-tr v-for="(value, key) in systemConfig.passwordResetTokenLifespanSettings">
            <b-th :stacked-heading="key">{{value}}</b-th>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>
  </div>
</template>

<script>
  import { mapActions } from 'vuex';

  export default {
    name: 'SystemConfig',
    data() {
      return {
        isInitialisingUI: false,
      };
    },
    created() {
      this.initialiseUI();
    },
    computed: {
      applicationSettings() {
        return this.$store.state.applicationSettings
      },
      systemConfig() {
        return this.$store.state.systemConfig
      },
      systemConfigIsLoading() {
        return this.$store.state.systemConfig.isLoading;
      },
    },
    methods: {
      ...mapActions('applicationSettings', ['getApplicationSettings']),
      ...mapActions('systemConfig', ['getSystemConfig']),
      async initialiseUI() {
        this.isInitialisingUI = true;
        await this.getSystemConfig()
          .catch((error) => {
            this.handleHttpError(error);
            this.$store.dispatch('systemSettings/clearState');
          });
        await this.getApplicationSettings()
          .catch((error) => {
            this.handleHttpError(error);
            this.$store.dispatch('ApplicationSettings/clearState');
          });
        this.isInitialisingUI = false;
      },
    },
  };
</script>

<style scoped>
  tr[role=row] th {
    font-weight:normal;
  }
</style>
