export default {
  methods: {
    handleHttpError(error) {
      const errorObject = {
        errorTitle: 'An error occurred',
        errorMessage: error.response.statusText,
        httpStatusCode: error.response.status,
      };

      if (error.response.status === 401) {
        // Handle 401:
        errorObject.errorTitle = 'Session expired';
        errorObject.errorMessage = 'Sorry, looks like your session has expired. Please log in again.';
        this.$store.dispatch('authentication/logout');
        this.$router.push('/login');
      } else if (error.response.status === 403) {
        // Handle 403:
        errorObject.errorTitle = 'Access denied';
        errorObject.errorMessage = 'You are not authorised to perform this operation. If you think you should be authorised, please contact a system administrator.';

        // If user is logged in, log them out and redirect to login screen:
        // (Forbidden whilst logged in suggests account has been disabled)
        if (this.$store.getters['authentication/isLoggedIn']) {
          this.$store.dispatch('authentication/logout');
          this.$router.push('/login');
        }
      } else if (error.response.data?.error) {
        // Handle any other typed error we get back from the server:
        errorObject.errorMessage = error.response.data.error;

        if (error.response.data.errorDetails && error.response.data.errorDetails.length > 0) {
          // We got some further error details back from the API:
          errorObject.errorDetails = error.response.data.errorDetails;
        }
      } else {
        // Handle anything else we get back:
        errorObject.errorMessage = error.response.data;
      }
      this.$store.dispatch('error/showError', errorObject);
    },
    handleClientError(error) {
      const errorObject = {
        errorTitle: error.errorTitle,
        errorMessage: error.errorMessage,
      };
      this.$store.dispatch('error/showError', errorObject);
    },
  },
};
