import axios from 'axios';

export default {
  namespaced: true,
  state: {
    sections: null,
    totalSectionsCount: null,
    isLoading: false,
    selectedSectionId: null,
  },
  mutations: {
    setSections(state, data) {
      state.sections = data?.sections ?? null;
      state.totalSectionsCount = data?.totalSectionsCount ?? null;
    },
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    setSelectedSectionId(state, selectedSectionId) {
      state.selectedSectionId = selectedSectionId;
    },
  },
  actions: {
    getSections(context) {
      context.commit('setIsLoading', true);
      return axios.get('/api/sections')
        .then((result) => {
          context.commit('setSections', result.data);
        })
        .catch((error) => { throw error; })
        .finally(() => {
          context.commit('setIsLoading', false);
        });
    },
    searchSections(context, searchParams) {
      context.commit('setIsLoading', true);
      return axios.get('/api/sections/search', {
        params: {
          pageNumber: searchParams.pageNumber,
          pageSize: searchParams.pageSize,
        },
      })
        .then((result) => {
          context.commit('setSections', result.data);
        })
        .catch((error) => { throw error; })
        .finally(() => {
          context.commit('setIsLoading', false);
        });
    },
    createSection(context, section) {
      context.commit('setIsLoading', true);
      return axios.post('/api/sections', section)
        .catch((error) => { throw error; })
        .finally(() => {
          context.commit('setIsLoading', false);
        });
    },
    updateSection(context, section) {
      context.commit('setIsLoading', true);
      return axios.put(`/api/sections/${section.id}`, section)
        .catch((error) => { throw error; })
        .finally(() => {
          context.commit('setIsLoading', false);
        });
    },
    deleteSection(context, id) {
      context.commit('setIsLoading', true);
      return axios.delete(`/api/sections/${id}`)
        .catch((error) => { throw error; })
        .finally(() => {
          context.commit('setIsLoading', false);
        });
    },
    changeSelectedSection(context, selectedSectionId) {
      context.commit('setSelectedSectionId', selectedSectionId);
    },
    clearState(context) {
      context.commit('setSections', null);
    },
  },
};
