import axios from 'axios';

export default {
  namespaced: true,
  state: {
    worksheets: null,
    totalWorksheetsCount: null,
    isLoading: false,
    isDownloadingFile: false,
  },
  mutations: {
    setWorksheets(state, data) {
      state.worksheets = data?.worksheets ?? null;
      state.totalWorksheetsCount = data?.totalWorksheetsCount ?? null;
    },
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    setIsDownloadingFile(state, isDownloadingFile) {
      state.isDownloadingFile = isDownloadingFile;
    },
  },
  actions: {
    getWorksheets(context) {
      context.commit('setIsLoading', true);
      return axios.get('/api/worksheets')
        .then((result) => {
          context.commit('setWorksheets', result.data);
        })
        .catch((error) => { throw error; })
        .finally(() => {
          context.commit('setIsLoading', false);
        });
    },
    getWorksheetsByDownloadablePackId(context, request) {
      context.commit('setIsLoading', true);
      return axios.get(`/api/downloadablepacks/${request.id}/worksheets`, {
        params: {
          pageNumber: request.pageNumber,
          pageSize: request.pageSize,
        },
      })
        .then((result) => {
          context.commit('setWorksheets', result.data);
        })
        .catch((error) => { throw error; })
        .finally(() => {
          context.commit('setIsLoading', false);
        });
    },
    searchWorksheets(context, searchParams) {
      context.commit('setIsLoading', true);
      return axios.get('/api/worksheets/search', {
        params: {
          pageNumber: searchParams.pageNumber,
          pageSize: searchParams.pageSize,
          caseId: searchParams.caseId,
          itemId: searchParams.itemId,
          sectionId: searchParams.sectionId,
          taskTypeId: searchParams.taskTypeId,
          wordCategoryId: searchParams.wordCategoryId,
          itemSearchTerm: searchParams.itemSearchTerm,
          itemFirstLetter: searchParams.itemFirstLetter,
          itemWordLength: searchParams.itemWordLength,
        },
      })
        .then((result) => {
          context.commit('setWorksheets', result.data);
        })
        .catch((error) => { throw error; })
        .finally(() => {
          context.commit('setIsLoading', false);
        });
    },
    createWorksheet(context, worksheet) {
      context.commit('setIsLoading', true);

      const formData = new FormData();
      formData.append('name', worksheet.name);
      formData.append('caseId', worksheet.caseId);
      formData.append('itemId', worksheet.itemId);
      formData.append('sectionId', worksheet.sectionId);
      formData.append('taskTypeId', worksheet.taskTypeId);
      formData.append('file', worksheet.file);

      return axios.post('/api/worksheets', formData)
        .catch((error) => { throw error; })
        .finally(() => {
          context.commit('setIsLoading', false);
        });
    },
    updateWorksheet(context, worksheet) {
      context.commit('setIsLoading', true);

      const formData = new FormData();
      formData.append('id', worksheet.id);
      formData.append('name', worksheet.name);
      formData.append('caseId', worksheet.caseId);
      formData.append('itemId', worksheet.itemId);
      formData.append('sectionId', worksheet.sectionId);
      formData.append('taskTypeId', worksheet.taskTypeId);
      formData.append('file', worksheet.file);

      return axios.put(`/api/worksheets/${worksheet.id}`, formData)
        .catch((error) => { throw error; })
        .finally(() => {
          context.commit('setIsLoading', false);
        });
    },
    deleteWorksheet(context, id) {
      context.commit('setIsLoading', true);
      return axios.delete(`/api/worksheets/${id}`)
        .catch((error) => { throw error; })
        .finally(() => {
          context.commit('setIsLoading', false);
        });
    },
    getWorksheetDocument(context, id) {
      context.commit('setIsDownloadingFile', true);
      return axios.get(`/api/worksheets/${id}/document`, {
        responseType: 'blob',
      })
        .then((response) => response)
        .catch((error) => new Promise((resolve) => {
          const reader = new FileReader();
          reader.onload = () => {
            // eslint-disable-next-line no-param-reassign
            error.response.data = JSON.parse(reader.result);
            resolve(Promise.reject(error));
          };
          reader.readAsText(error.response.data);
        }))
        .finally(() => {
          context.commit('setIsDownloadingFile', false);
        });
    },
    clearState(context) {
      context.commit('setWorksheets', null);
    },
  },
};
