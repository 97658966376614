import axios from 'axios';

export default {
  namespaced: true,
  state: {
    isLoading: false,
  },
  mutations: {
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
  },
  actions: {
    changePassword(context, changePasswordRequest) {
      context.commit('setIsLoading', true);
      return axios.post('/api/account/changepassword', changePasswordRequest)
        .catch((error) => { throw error; })
        .finally(() => {
          context.commit('setIsLoading', false);
        });
    },
  },
};
