<template>
  <div>
    <div class="mb-3">
      <h5>Manage Word Categories - {{titleText}}</h5>
    </div>
    <div class="text-center" v-if="isInitialisingUI">
      <b-spinner label="Spinning"></b-spinner>
    </div>
    <div v-else>
      <div v-if="wordCategory">
        <b-form
          autocomplete="off"
          v-on:submit="formSubmit">
          <AdminDropdown for="sections"
            preventSelectDefaultItem
            v-on:dropdownItemChanged="selectedSectionChanged" />
          <b-form-group label="Word Category Name:"
            label-cols-lg="2"
            content-cols-lg="10">
            <b-form-input
              v-model="wordCategory.name"
              placeholder="Enter name"
              required>
            </b-form-input>
          </b-form-group>
          <b-button class="mr-2"
            title="Cancel"
            v-bind:disabled="wordCategoriesIsLoading"
            v-on:click="cancelClick">
            <b-icon-x />
          </b-button>
          <b-button class="mr-2" type="submit" variant="primary"
            v-bind:title="submitText"
            v-bind:disabled="wordCategoriesIsLoading">
            <span v-if="isNew"><b-icon-plus /></span>
            <span v-else><b-icon-check /></span>
            <span v-if="wordCategoriesIsLoading">
              <b-spinner label="Spinning" small></b-spinner>
            </span>
          </b-button>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>

import { mapActions } from 'vuex';
import AdminDropdown from '../components/AdminDropdown.vue';

export default {
  name: 'WordCategoryDetails',
  components: { AdminDropdown },
  data() {
    return {
      isInitialisingUI: false,
    };
  },
  props: {
    wordCategory: {},
    new: { type: Boolean },
  },
  created() {
    if (!this.wordCategory) {
      this.$router.go(-1);
    } else {
      this.initialiseUI();
    }
  },
  computed: {
    isNew() {
      return this.new;
    },
    sections() {
      return this.$store.state.sections.sections;
    },
    wordCategoriesIsLoading() {
      return this.$store.state.wordCategories.isLoading;
    },
    titleText() {
      return this.new === true ? 'Add Word Category' : 'Edit Word Category';
    },
    submitText() {
      return this.new === true ? 'Add Word Category' : 'Update Word Category';
    },
  },
  methods: {
    ...mapActions('sections', ['getSections']),
    ...mapActions('wordCategories', ['createWordCategory', 'updateWordCategory']),
    async initialiseUI() {
      this.isInitialisingUI = true;

      await this.getSections()
        .then(() => {
          this.$store.dispatch('sections/changeSelectedSection', this.wordCategory.sectionId);
        })
        .catch((error) => {
          this.handleHttpError(error);
          this.$store.dispatch('sections/clearState');
          this.$store.dispatch('wordCategories/clearState');
        });

      this.isInitialisingUI = false;
    },
    selectedSectionChanged() {
      this.wordCategory.sectionId = this.$store.state.sections.selectedSectionId;
    },
    cancelClick() {
      this.$router.go(-1);
    },
    async formSubmit(event) {
      event.preventDefault();

      if (this.new) {
        await this.createWordCategory(this.wordCategory)
          .then(() => { this.$router.go(-1); })
          .catch((error) => { this.handleHttpError(error); });
      } else {
        await this.updateWordCategory(this.wordCategory)
          .then(() => { this.$router.go(-1); })
          .catch((error) => { this.handleHttpError(error); });
      }
    },
  },
};
</script>
