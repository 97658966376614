<template>
  <div>
    <b-card
      title="Reset Your Password"
      tag="article"
      style="max-width: 400px;"
      class="mb-2">
      <div v-if="!resetPasswordRequestSuccessful">
        <b-form
          v-on:submit="onSubmit">
          <b-form-group
            id="email-address-group"
            label="Enter your email address and we will send you a password reset email:"
            label-for="email-input">
            <b-form-input
              id="email-input"
              type="email"
              placeholder="Enter email address"
              required
              v-model="emailAddress">
            </b-form-input>
          </b-form-group>

          <b-button type="submit" variant="primary" v-bind:disabled="isLoading">
            Send Password Reset Email
            <span v-if="isLoading"><b-spinner label="Spinning" small></b-spinner></span>
            <span v-else><b-icon-box-arrow-in-right /></span>
          </b-button>
        </b-form>
      </div>

      <div v-else>
          <p>
            Thank you. Please check your email for a link to reset your password.
            If it doesn't appear within a few minutes, please check your junk.
          </p>
          <p>
            <b-button variant="primary"
            v-on:click="goToLoginClick">
              Go to Login <b-icon-arrow-right />
            </b-button>
          </p>
      </div>
    </b-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'ForgottenPassword',
  data() {
    return {
      resetPasswordRequestSuccessful: false,
      emailAddress: null,
    };
  },
  computed: {
    isLoading() {
      return this.$store.state.authentication.isLoading;
    },
  },
  methods: {
    ...mapActions('authentication', ['requestPasswordReset']),
    onSubmit(event) {
      event.preventDefault();
      this.doRequestPasswordReset();
    },
    async doRequestPasswordReset() {
      this.$store.dispatch('error/dismissError');

      const request = {
        emailAddress: this.emailAddress,
      };

      await this.requestPasswordReset(request)
        .then(() => {
          this.resetPasswordRequestSuccessful = true;
        })
        .catch((error) => {
          this.resetPasswordRequestSuccessful = false;
          this.handleHttpError(error);
        });
    },
    goToLoginClick() {
      this.$router.replace('/login');
    },
  },
};
</script>
