import axios from 'axios';

export default {
  namespaced: true,
  state: {
    fileStorageSettings: null,
    jwtSettings: null,
    emailSettings: null,
    functionsSettings: null,
    accountConfirmationLifespanSettings: null,
    accountConfirmationTokenLifespanSettings: null,
    passwordResetLifespanSettings: null,
    passwordResetTokenLifespanSettings: null,
    aphasiaSqlConnection: null,
    isLoading: false,
  },
  mutations: {
    setFileStorageSettings(state, fileStorageSettings) {
      state.fileStorageSettings = fileStorageSettings;
    },
    setJwtSettings(state, jwtSettings) {
      state.jwtSettings = jwtSettings;
    },
    setEmailSettings(state, emailSettings) {
      state.emailSettings = emailSettings;
    },
    setFunctionsSettings(state, functionsSettings) {
      state.functionsSettings = functionsSettings;
    },
    setAccountConfirmationLifespanSettings(state, accountConfirmationLifespanSettings) {
      state.accountConfirmationLifespanSettings = accountConfirmationLifespanSettings;
    },
    setAccountConfirmationTokenLifespanSettings(state, accountConfirmationTokenLifespanSettings) {
      state.accountConfirmationTokenLifespanSettings = accountConfirmationTokenLifespanSettings;
    },
    setPasswordResetLifespanSettings(state, passwordResetLifespanSettings) {
      state.passwordResetLifespanSettings = passwordResetLifespanSettings;
    },
    setPasswordResetTokenLifespanSettings(state, passwordResetTokenLifespanSettings) {
      state.passwordResetTokenLifespanSettings = passwordResetTokenLifespanSettings;
    },
    setConnectionString(state, aphasiaSqlConnection) {
      state.aphasiaSqlConnection = aphasiaSqlConnection;
    },
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
  },
  actions: {
    getSystemConfig(context) {
      context.commit('setIsLoading', true);
      return axios.get('/api/systemconfig')
        .then((result) => {
          context.commit('setFileStorageSettings', result.data.fileStorageSettings);
          context.commit('setJwtSettings', result.data.jwtSettings);
          context.commit('setEmailSettings', result.data.emailSettings);
          context.commit('setFunctionsSettings', result.data.functionsSettings);
          context.commit('setAccountConfirmationLifespanSettings', result.data.accountConfirmationLifespanSettings);
          context.commit('setAccountConfirmationTokenLifespanSettings', result.data.accountConfirmationTokenLifespanSettings);
          context.commit('setPasswordResetLifespanSettings', result.data.passwordResetLifespanSettings);
          context.commit('setPasswordResetTokenLifespanSettings', result.data.passwordResetTokenLifespanSettings);
          context.commit('setConnectionString', result.data.aphasiaSqlConnection);
        })
        .catch((error) => { throw error; })
        .finally(() => {
          context.commit('setIsLoading', false);
        });
    },
    clearState(context) {
      context.commit('setFileStorageSettings', null);
      context.commit('setJwtSettings', null);
      context.commit('setEmailSettings', null);
      context.commit('setFunctionsSettings', null);
      context.commit('setAccountConfirmationLifespanSettings', null);
      context.commit('setAccountConfirmationTokenLifespanSettings', null);
      context.commit('setPasswordResetLifespanSettings', null);
      context.commit('setPasswordResetTokenLifespanSettings', null);
      context.commit('setConnectionString', null);
    },
  },
};
