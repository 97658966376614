<template>
  <div>
    <div class="mb-3">
      <h5>Manage Letter Case - {{titleText}}</h5>
    </div>
    <div v-if="caseInstance">
      <b-form
        autocomplete="off"
        v-on:submit="formSubmit">
        <b-form-group label="Letter Case Name:"
          label-cols-lg="2"
          content-cols-lg="10">
          <b-form-input
            v-model="caseInstance.name"
            placeholder="Enter name"
            required
          ></b-form-input>
        </b-form-group>
        <b-button class="mr-2"
          title="Cancel"
          v-bind:disabled="casesIsLoading"
          v-on:click="cancelClick">
          <b-icon-x />
        </b-button>
        <b-button class="mr-2" type="submit" variant="primary"
          v-bind:title="submitText"
          v-bind:disabled="casesIsLoading">
          <span v-if="isNew"><b-icon-plus /></span>
          <span v-else><b-icon-check /></span>
          <span v-if="casesIsLoading"><b-spinner label="Spinning" small></b-spinner></span>
        </b-button>
      </b-form>
    </div>
  </div>
</template>

<script>

import { mapActions } from 'vuex';

export default {
  name: 'CaseDetails',
  props: {
    caseInstance: {},
    new: { type: Boolean },
  },
  created() {
    if (!this.caseInstance) {
      this.$router.go(-1);
    }
  },
  computed: {
    isNew() {
      return this.new;
    },
    casesIsLoading() {
      return this.$store.state.cases.isLoading;
    },
    titleText() {
      return this.new === true ? 'Add Letter Case' : 'Edit Letter Case';
    },
    submitText() {
      return this.new === true ? 'Add Letter Case' : 'Update Letter Case';
    },
  },
  methods: {
    ...mapActions('cases', ['createCase', 'updateCase']),
    cancelClick() {
      this.$router.go(-1);
    },
    async formSubmit(event) {
      event.preventDefault();

      if (this.new) {
        await this.createCase(this.caseInstance)
          .then(() => { this.$router.go(-1); })
          .catch((error) => { this.handleHttpError(error); });
      } else {
        await this.updateCase(this.caseInstance)
          .then(() => { this.$router.go(-1); })
          .catch((error) => { this.handleHttpError(error); });
      }
    },
  },
};
</script>
