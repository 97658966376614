export default {
  namespaced: true,
  state: {
    selectedWordCategoryId: null,
    selectedItemId: null,
    selectedCaseId: null,
    itemSearchTerm: null,
    selectedItemFirstLetter: null,
    selectedItemWordLength: null,
    itemFirstLetters: [
      { id: 'a', name: 'a' },
      { id: 'b', name: 'b' },
      { id: 'c', name: 'c' },
      { id: 'd', name: 'd' },
      { id: 'e', name: 'e' },
      { id: 'f', name: 'f' },
      { id: 'g', name: 'g' },
      { id: 'h', name: 'h' },
      { id: 'i', name: 'i' },
      { id: 'j', name: 'j' },
      { id: 'k', name: 'k' },
      { id: 'l', name: 'l' },
      { id: 'm', name: 'm' },
      { id: 'n', name: 'n' },
      { id: 'o', name: 'o' },
      { id: 'p', name: 'p' },
      { id: 'q', name: 'q' },
      { id: 'r', name: 'r' },
      { id: 's', name: 's' },
      { id: 't', name: 't' },
      { id: 'u', name: 'u' },
      { id: 'v', name: 'v' },
      { id: 'w', name: 'w' },
      { id: 'x', name: 'x' },
      { id: 'y', name: 'y' },
      { id: 'z', name: 'z' },
    ],
    itemWordLengths: [
      { id: '1', name: '1' },
      { id: '2', name: '2' },
      { id: '3', name: '3' },
      { id: '4', name: '4' },
      { id: '5', name: '5' },
      { id: '6', name: '6' },
      { id: '7', name: '7' },
      { id: '8', name: '8' },
      { id: '9', name: '9' },
      { id: '10', name: '10' },
      { id: '11', name: '11' },
      { id: '12', name: '12' },
      { id: '13', name: '13' },
      { id: '14', name: '14' },
      { id: '15', name: '15' },
      { id: '16', name: '16' },
      { id: '17', name: '17' },
      { id: '18', name: '18' },
      { id: '19', name: '19' },
      { id: '20', name: '20' },
    ],
  },
  mutations: {
    setSelectedWordCategoryId(state, selectedWordCategoryId) {
      state.selectedWordCategoryId = selectedWordCategoryId;
    },
    setSelectedItemId(state, selectedItemId) {
      state.selectedItemId = selectedItemId;
    },
    setSelectedCaseId(state, selectedCaseId) {
      state.selectedCaseId = selectedCaseId;
    },
    setItemSearchTerm(state, itemSearchTerm) {
      state.itemSearchTerm = itemSearchTerm;
    },
    setSelectedItemFirstLetter(state, selectedItemFirstLetter) {
      state.selectedItemFirstLetter = selectedItemFirstLetter;
    },
    setSelectedItemWordLength(state, selectedItemWordLength) {
      state.selectedItemWordLength = selectedItemWordLength;
    },
  },
  actions: {
    changeSelectedWordCategory(context, selectedWordCategoryId) {
      context.commit('setSelectedWordCategoryId', selectedWordCategoryId);
    },
    changeSelectedItem(context, selectedItemId) {
      context.commit('setSelectedItemId', selectedItemId);
    },
    changeSelectedCase(context, selectedCaseId) {
      context.commit('setSelectedCaseId', selectedCaseId);
    },
    changeItemSearchTerm(context, itemSearchTerm) {
      context.commit('setItemSearchTerm', itemSearchTerm);
    },
    changeSelectedItemFirstLetter(context, selectedItemFirstLetter) {
      context.commit('setSelectedItemFirstLetter', selectedItemFirstLetter);
    },
    changeSelectedItemWordLength(context, selectedItemWordLength) {
      context.commit('setSelectedItemWordLength', selectedItemWordLength);
    },
  },
};
