import axios from 'axios';

export default {
  namespaced: true,
  state: {
    systemAdministrators: null,
    isLoading: false,
    selectedSystemAdministratorId: null,
  },
  mutations: {
    setSystemAdministrators(state, systemAdministrators) {
      state.systemAdministrators = systemAdministrators;
    },
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    setSelectedSystemAdministratorId(state, selectedUserId) {
      state.selectedSystemAdministratorId = selectedUserId;
    },
  },
  actions: {
    getSystemAdministrators(context) {
      context.commit('setIsLoading', true);
      return axios.get('/api/systemadministrators')
        .then((result) => {
          context.commit('setSystemAdministrators', result.data.systemAdministrators);
        })
        .catch((error) => { throw error; })
        .finally(() => {
          context.commit('setIsLoading', false);
        });
    },
    createSystemAdministrator(context, systemAdministrator) {
      context.commit('setIsLoading', true);
      return axios.post('/api/systemadministrators', systemAdministrator)
        .catch((error) => { throw error; })
        .finally(() => {
          context.commit('setIsLoading', false);
        });
    },
    updateSystemAdministrator(context, systemAdministrator) {
      context.commit('setIsLoading', true);
      return axios.put(`/api/systemadministrators/${systemAdministrator.id}`, systemAdministrator)
        .catch((error) => { throw error; })
        .finally(() => {
          context.commit('setIsLoading', false);
        });
    },
    deleteSystemAdministrator(context, id) {
      context.commit('setIsLoading', true);
      return axios.delete(`/api/systemadministrators/${id}`)
        .catch((error) => { throw error; })
        .finally(() => {
          context.commit('setIsLoading', false);
        });
    },
    changeSelectedSystemAdministrator(context, selectedUserId) {
      context.commit('setSelectedSystemAdministratorId', selectedUserId);
    },
    clearState(context) {
      context.commit('setSystemAdministrators', null);
    },
  },
};
