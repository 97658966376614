<template>
  <div>
    <b-form-group
      v-bind:label="documentNameLabel">
      <b-link v-on:click="downloadClick">
        {{documentName}}
        <b-icon-download />
      </b-link>
      <b-button size="sm" class="ml-3"
        variant="primary"
        v-on:click="editClick">
        Edit
      </b-button>
      <b-collapse class="mt-2"
        v-model="editPanelIsOpen">
        <b-card>
          <div>
            <b-form-group v-bind:label="editLabel"
              label-cols-sm="4"
              label-cols-xl="3"
              content-cols-sm
              content-cols-lg="6">
              <b-form-file
                accept="application/pdf"
                v-model="file"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here...">
              </b-form-file>
            </b-form-group>
            <b-button size="sm" variant="secondary"
              v-on:click="cancelClick">
              Cancel
            </b-button>
            <b-button size="sm" variant="primary" class="ml-2"
              v-bind:disabled="saveButtonIsDisabled"
              v-on:click="saveClick">
              Save
            </b-button>
          </div>
        </b-card>
      </b-collapse>
    </b-form-group>
  </div>
</template>

<script>
export default {
  name: 'SystemDocumentItem',
  props: {
    documentName: String,
  },
  data() {
    return {
      file: null,
      editPanelIsOpen: false,
    };
  },
  computed: {
    documentNameLabel() {
      return `${this.documentName} document:`;
    },
    editLabel() {
      return `Update ${this.documentName} document:`;
    },
    saveButtonIsDisabled() {
      return this.file === null;
    },
  },
  methods: {
    editClick() {
      this.editPanelIsOpen = !this.editPanelIsOpen;
    },
    cancelClick() {
      this.editPanelIsOpen = false;
      this.file = null;
    },
    async saveClick() {
      this.$emit('updateDocument', this.file);
      this.editPanelIsOpen = false;
      this.file = null;
    },
    async downloadClick() {
      this.$emit('downloadDocument');
    },
  },
};
</script>
