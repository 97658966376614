import axios from 'axios';

export default {
  namespaced: true,
  state: {
    organisations: null,
    totalOrganisationsCount: null,
    isLoading: false,
    selectedOrganisationId: null,
  },
  mutations: {
    setOrganisations(state, data) {
      state.organisations = data?.organisations ?? null;
      state.totalOrganisationsCount = data?.totalOrganisationsCount ?? null;
    },
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    setSelectedOrganisationId(state, selectedOrganisationId) {
      state.selectedOrganisationId = selectedOrganisationId;
    },
  },
  actions: {
    getOrganisations(context) {
      context.commit('setIsLoading', true);
      return axios.get('/api/organisations')
        .then((result) => {
          context.commit('setOrganisations', result.data);
        })
        .catch((error) => { throw error; })
        .finally(() => {
          context.commit('setIsLoading', false);
        });
    },
    searchOrganisations(context, searchParams) {
      context.commit('setIsLoading', true);
      return axios.get('/api/organisations/search', {
        params: {
          pageNumber: searchParams.pageNumber,
          pageSize: searchParams.pageSize,
        },
      })
        .then((result) => {
          context.commit('setOrganisations', result.data);
        })
        .catch((error) => { throw error; })
        .finally(() => {
          context.commit('setIsLoading', false);
        });
    },
    createOrganisation(context, organisation) {
      context.commit('setIsLoading', true);
      return axios.post('/api/organisations', organisation)
        .catch((error) => { throw error; })
        .finally(() => {
          context.commit('setIsLoading', false);
        });
    },
    updateOrganisation(context, organisation) {
      context.commit('setIsLoading', true);
      return axios.put(`/api/organisations/${organisation.id}`, organisation)
        .catch((error) => { throw error; })
        .finally(() => {
          context.commit('setIsLoading', false);
        });
    },
    deleteOrganisation(context, id) {
      context.commit('setIsLoading', true);
      return axios.delete(`/api/organisations/${id}`)
        .catch((error) => { throw error; })
        .finally(() => {
          context.commit('setIsLoading', false);
        });
    },
    changeSelectedOrganisation(context, selectedOrganisationId) {
      context.commit('setSelectedOrganisationId', selectedOrganisationId);
    },
    clearState(context) {
      context.commit('setOrganisations', null);
    },
  },
};
