import axios from 'axios';

export default {
  namespaced: true,
  state: {
    cases: null,
    isLoading: false,
    selectedCaseId: null,
  },
  mutations: {
    setCases(state, cases) {
      state.cases = cases;
    },
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    setSelectedCaseId(state, selectedCaseId) {
      state.selectedCaseId = selectedCaseId;
    },
  },
  actions: {
    getCases(context) {
      context.commit('setIsLoading', true);
      return axios.get('/api/cases')
        .then((result) => {
          context.commit('setCases', result.data.cases);
        })
        .catch((error) => { throw error; })
        .finally(() => {
          context.commit('setIsLoading', false);
        });
    },
    createCase(context, c) {
      context.commit('setIsLoading', true);
      return axios.post('/api/cases', c)
        .catch((error) => { throw error; })
        .finally(() => {
          context.commit('setIsLoading', false);
        });
    },
    updateCase(context, c) {
      context.commit('setIsLoading', true);
      return axios.put(`/api/cases/${c.id}`, c)
        .catch((error) => { throw error; })
        .finally(() => {
          context.commit('setIsLoading', false);
        });
    },
    deleteCase(context, id) {
      context.commit('setIsLoading', true);
      return axios.delete(`/api/cases/${id}`)
        .catch((error) => { throw error; })
        .finally(() => {
          context.commit('setIsLoading', false);
        });
    },
    changeSelectedCase(context, selectedCaseId) {
      context.commit('setSelectedCaseId', selectedCaseId);
    },
    clearState(context) {
      context.commit('setCases', null);
    },
  },
};
