<template>
  <div>
    <b-alert variant="success"
      fade
      v-bind:show="alertCountDownSeconds"
      v-on:dismiss-count-down="alertCountDownTick">
      Settings saved successfully
    </b-alert>
    <div class="mb-3">
      <h5>Manage System Settings</h5>
    </div>
    <div class="text-center" v-if="isInitialisingUI">
      <b-spinner label="Spinning"></b-spinner>
    </div>
    <div v-else>
      <b-form
        autocomplete="off"
        v-on:submit="formSubmit">
        <b-form-group id="input-group-broadcastMessageIsEnabled">
          <b-form-checkbox id="input-broadcastMessageIsEnabled"
            v-model="systemSettings.broadcastMessageIsEnabled">
            Enable Broadcast Message?
          </b-form-checkbox>
          <b-form-text>The broadcast message will appear on the login screen.</b-form-text>
        </b-form-group>
        <b-collapse v-model="systemSettings.broadcastMessageIsEnabled" class="mt-2 mb-2">
          <b-card>
            <b-card-text>Broadcast Message:</b-card-text>
            <b-form-group label="Severity">
              <b-form-radio-group v-model="systemSettings.broadcastMessageSeverity"
                stacked>
                <b-form-radio value="0">
                  <b-icon-info-circle variant="info" /> <strong>Info</strong>
                </b-form-radio>
                <b-form-radio  value="1">
                  <b-icon-exclamation-triangle variant="warning" /> <strong>Warning</strong>
                </b-form-radio>
                <b-form-radio value="2">
                  <b-icon-exclamation-triangle variant="danger" /> <strong>Danger</strong>
                </b-form-radio>
              </b-form-radio-group>
            </b-form-group>
            <b-form-group id="input-group-broadcastMessageTitle"
              label="Title:" label-for="input-broadcastMessageTitle">
              <b-form-input id="input-broadcastMessageTitle"
                v-model="systemSettings.broadcastMessageTitle"
                placeholder="Broadcast message title">
              </b-form-input>
            </b-form-group>
            <b-form-group id="input-group-broadcastMessageBody"
              label="Body:" label-for="input-broadcastMessageBody">
              <b-form-input id="input-broadcastMessageBody"
                v-model="systemSettings.broadcastMessageBody"
                placeholder="Broadcast message body">
              </b-form-input>
            </b-form-group>
            <b-form-group id="input-group-broadcastMessagePreview"
              label="Preview:">
              <broadcast-message />
            </b-form-group>
          </b-card>
        </b-collapse>
        <b-form-group id="input-group-restrictAccess">
          <b-form-checkbox id="input-restrictAccess"
            v-model="systemSettings.restrictAccessToSystemAdministratorsOnly">
            Restrict access to System Administrators only?
          </b-form-checkbox>
          <b-form-text>
            <b-icon-exclamation-triangle variant="danger" />
            Removes system access to all users who are not System Administrators.
          </b-form-text>
        </b-form-group>
        <b-button class="mr-2"
          title="Cancel"
          v-bind:disabled="systemSettingsIsLoading"
          v-on:click="cancelClick">
          <b-icon-x />
        </b-button>
        <b-button class="mr-2" type="submit" variant="primary"
          title="Save"
          v-bind:disabled="systemSettingsIsLoading">
          <b-icon-check />
          <span v-if="systemSettingsIsLoading">
            <b-spinner label="Spinning" small /></span>
        </b-button>
      </b-form>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import BroadcastMessage from '../components/BroadcastMessage.vue';

export default {
  name: 'SystemSettings',
  components: { BroadcastMessage },
  data() {
    return {
      isInitialisingUI: false,
      alertCountDownSeconds: 0,
    };
  },
  created() {
    this.initialiseUI();
  },
  computed: {
    systemSettings() {
      return this.$store.state.systemSettings.systemSettings;
    },
    systemSettingsIsLoading() {
      return this.$store.state.systemSettings.isLoading;
    },
  },
  methods: {
    ...mapActions('systemSettings', ['getSystemSettings', 'upsertSystemSettings']),
    async initialiseUI() {
      this.isInitialisingUI = true;
      await this.getSystemSettings()
        .catch((error) => {
          this.handleHttpError(error);
          this.$store.dispatch('systemSettings/clearState');
        });
      this.isInitialisingUI = false;
    },
    async cancelClick() {
      this.$bvModal.msgBoxConfirm(
        'Are you sure you want to discard your changes?', {
          title: 'Discard changes to System Settings',
          okVariant: 'primary',
          centered: true,
          hideHeaderClose: false,
        },
      )
        .then(async (value) => {
          // 'value' is true ONLY if OK is clicked:
          if (value) {
            this.isInitialisingUI = true;
            await this.getSystemSettings()
              .catch((error) => {
                this.handleHttpError(error);
                this.$store.dispatch('systemSettings/clearState');
              });
            this.isInitialisingUI = false;
          }
        });
    },
    async formSubmit(event) {
      event.preventDefault();

      this.$bvModal.msgBoxConfirm(
        'Are you sure you want to save these System Settings?', {
          title: 'Save System Settings',
          okVariant: 'primary',
          centered: true,
          hideHeaderClose: false,
        },
      )
        .then(async (value) => {
          // 'value' is true ONLY if OK is clicked:
          if (value) {
            if (this.systemSettings.restrictAccessToSystemAdministratorsOnly) {
              this.restrictAccessWarn();
            } else {
              this.doUpsertSystemSettings();
            }
          }
        });
    },
    restrictAccessWarn() {
      const messageDiv = this.$createElement('div', [
        this.$createElement('p', [
          this.$createElement('strong', 'Warning: '),
          'You have ticked "Restrict access to System Administrators only."',
        ]),
        this.$createElement('p', [
          'This will remove system access to all users who are not System Administrators.',
        ]),
        this.$createElement('p', [
          'Any non System Administrators who are already logged in will be logged out and will not be able to log in again.',
        ]),
        this.$createElement('p', [
          'Are you sure you want to proceed?',
        ]),
      ]);

      this.$bvModal.msgBoxConfirm(
        messageDiv, {
          title: 'Restrict access to System Administrators only',
          okVariant: 'danger',
          centered: true,
          hideHeaderClose: false,
        },
      )
        .then(async (value) => {
          // 'value' is true ONLY if OK is clicked:
          if (value) {
            this.doUpsertSystemSettings();
          }
        });
    },
    async doUpsertSystemSettings() {
      await this.upsertSystemSettings(this.systemSettings)
        .then(() => { this.alertCountDownSeconds = 2; })
        .catch((error) => { this.handleHttpError(error); });
    },
    alertCountDownTick(dismissCountDown) {
      this.alertCountDownSeconds = dismissCountDown;
    },
  },
};
</script>
