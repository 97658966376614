<template>
  <div>
    <b-navbar fixed="bottom" type="dark" variant="dark" class="d-flex justify-content-center">
      <b-navbar-nav>
        <b-nav-item href="https://www.ncl.ac.uk" target="_blank">
          &copy; {{currentYear}} Newcastle University
        </b-nav-item>
      </b-navbar-nav>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: 'TheFooter',
  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
  },
};
</script>
