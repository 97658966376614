import axios from 'axios';

export default {
  namespaced: true,
  state: {
    wordCategories: null,
    totalWordCategoriesCount: null,
    isLoading: false,
    selectedWordCategoryId: null,
  },
  mutations: {
    setWordCategories(state, data) {
      state.wordCategories = data?.wordCategories ?? null;
      state.totalWordCategoriesCount = data?.totalWordCategoriesCount ?? null;
    },
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    setSelectedWordCategoryId(state, selectedWordCategoryId) {
      state.selectedWordCategoryId = selectedWordCategoryId;
    },
  },
  actions: {
    getWordCategories(context) {
      context.commit('setIsLoading', true);
      return axios.get('/api/wordcategories')
        .then((result) => {
          context.commit('setWordCategories', result.data);
        })
        .catch((error) => { throw error; })
        .finally(() => {
          context.commit('setIsLoading', false);
        });
    },
    searchWordCategories(context, searchParams) {
      context.commit('setIsLoading', true);
      return axios.get('/api/wordcategories/search', {
        params: {
          pageNumber: searchParams.pageNumber,
          pageSize: searchParams.pageSize,
          sectionId: searchParams.sectionId,
        },
      })
        .then((result) => {
          context.commit('setWordCategories', result.data);
        })
        .catch((error) => { throw error; })
        .finally(() => {
          context.commit('setIsLoading', false);
        });
    },
    createWordCategory(context, wordCategory) {
      context.commit('setIsLoading', true);

      const formData = new FormData();
      formData.append('name', wordCategory.name);
      formData.append('sectionId', wordCategory.sectionId);

      return axios.post('/api/wordcategories', formData)
        .catch((error) => { throw error; })
        .finally(() => {
          context.commit('setIsLoading', false);
        });
    },
    updateWordCategory(context, wordCategory) {
      context.commit('setIsLoading', true);

      const formData = new FormData();
      formData.append('id', wordCategory.id);
      formData.append('name', wordCategory.name);
      formData.append('sectionId', wordCategory.sectionId);

      return axios.put(`/api/wordcategories/${wordCategory.id}`, formData)
        .catch((error) => { throw error; })
        .finally(() => {
          context.commit('setIsLoading', false);
        });
    },
    deleteWordCategory(context, id) {
      context.commit('setIsLoading', true);
      return axios.delete(`/api/wordcategories/${id}`)
        .catch((error) => { throw error; })
        .finally(() => {
          context.commit('setIsLoading', false);
        });
    },
    changeSelectedWordCategory(context, selectedWordCategoryId) {
      context.commit('setSelectedWordCategoryId', selectedWordCategoryId);
    },
    clearState(context) {
      context.commit('setWordCategories', null);
    },
  },
};
