import axios from 'axios';

export default {
  namespaced: true,
  state: {
    systemSettings: null,
    isLoading: false,
  },
  mutations: {
    setSystemSettings(state, systemSettings) {
      state.systemSettings = systemSettings;
    },
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
  },
  getters: {
    getBroadcastMessageVariant(state) {
      const severity = parseInt(state.systemSettings?.broadcastMessageSeverity, 10);
      switch (severity) {
        case 0:
          return 'info';
        case 1:
          return 'warning';
        case 2:
          return 'danger';
        default:
          return 'light';
      }
    },
  },
  actions: {
    getSystemSettings(context) {
      context.commit('setIsLoading', true);
      return axios.get('/api/systemsettings')
        .then((result) => {
          context.commit('setSystemSettings', result.data.systemSettings);
        })
        .catch((error) => { throw error; })
        .finally(() => {
          context.commit('setIsLoading', false);
        });
    },
    upsertSystemSettings(context, settings) {
      context.commit('setIsLoading', true);
      return axios.post('/api/systemsettings', settings)
        .catch((error) => { throw error; })
        .finally(() => {
          context.commit('setIsLoading', false);
        });
    },
    clearState(context) {
      context.commit('setSystemSettings', null);
    },
  },
};
