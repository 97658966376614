import axios from 'axios';

export default {
  namespaced: true,
  state: {
    items: null,
    totalItemsCount: null,
    isLoading: false,
    selectedItemId: null,
  },
  mutations: {
    setItems(state, data) {
      state.items = data?.items ?? null;
      state.totalItemsCount = data?.totalItemsCount ?? null;
    },
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    setSelectedItemId(state, selectedItemId) {
      state.selectedItemId = selectedItemId;
    },
  },
  actions: {
    getItems(context) {
      context.commit('setIsLoading', true);
      return axios.get('/api/items')
        .then((result) => {
          context.commit('setItems', result.data);
        })
        .catch((error) => { throw error; })
        .finally(() => {
          context.commit('setIsLoading', false);
        });
    },
    searchItems(context, searchParams) {
      context.commit('setIsLoading', true);
      return axios.get('/api/items/search', {
        params: {
          pageNumber: searchParams.pageNumber,
          pageSize: searchParams.pageSize,
          sectionId: searchParams.sectionId,
          wordCategoryId: searchParams.wordCategoryId,
        },
      })
        .then((result) => {
          context.commit('setItems', result.data);
        })
        .catch((error) => { throw error; })
        .finally(() => {
          context.commit('setIsLoading', false);
        });
    },
    createItem(context, item) {
      context.commit('setIsLoading', true);

      const formData = new FormData();
      formData.append('name', item.name);
      formData.append('wordCategoryId', item.wordCategoryId);

      return axios.post('/api/items', formData)
        .catch((error) => { throw error; })
        .finally(() => {
          context.commit('setIsLoading', false);
        });
    },
    updateItem(context, item) {
      context.commit('setIsLoading', true);

      const formData = new FormData();
      formData.append('id', item.id);
      formData.append('name', item.name);
      formData.append('wordCategoryId', item.wordCategoryId);

      return axios.put(`/api/items/${item.id}`, formData)
        .catch((error) => { throw error; })
        .finally(() => {
          context.commit('setIsLoading', false);
        });
    },
    deleteItem(context, id) {
      context.commit('setIsLoading', true);
      return axios.delete(`/api/items/${id}`)
        .catch((error) => { throw error; })
        .finally(() => {
          context.commit('setIsLoading', false);
        });
    },
    changeSelectedItem(context, selectedItemId) {
      context.commit('setSelectedItemId', selectedItemId);
    },
    clearState(context) {
      context.commit('setItems', null);
    },
  },
};
