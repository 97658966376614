<template>
  <div>
    <div>
      <h3>Edit Downloadable Pack</h3>
    </div>
    <div v-if="downloadablePack">
      <div class="text-center" v-if="pageIsBusy">
        <b-spinner label="Spinning"></b-spinner>
      </div>
      <div v-else>
        <b-form
          autocomplete="off"
          v-on:submit="formSubmit">
          <b-form-group id="input-group-tasktype" label="Task Type:" label-for="input-tasktype">
            <b-form-input
              id="input-tasktype"
              v-model="downloadablePack.taskTypeName"
              disabled>
            </b-form-input>
          </b-form-group>
          <b-form-group id="input-group-name" label="Name:" label-for="input-name">
            <b-form-input
              id="input-name"
              v-model="downloadablePack.name"
              placeholder="Enter name"
              required>
            </b-form-input>
          </b-form-group>

          Worksheets:
          <b-table striped bordered hover
            stacked="lg"
            v-bind:items="worksheets"
            v-bind:fields="tableFields"
            v-bind:busy="tableIsBusy"
            show-empty
            empty-text="No data to show">
            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle"></b-spinner>
              </div>
            </template>
            <template #cell(actions)="data">
              <b-button class="mr-2" size="sm" variant="info"
                title="Download Document"
                v-on:click="downloadWorksheetClick(data.item)">
                <b-icon-download />
              </b-button>
            </template>
          </b-table>
          <b-pagination
            v-model="pageNumber"
            v-bind:total-rows="totalWorksheetsCount"
            v-bind:per-page="itemsCountPerPage"
            v-bind:disabled="tableIsBusy"
            v-on:change="onPageChange"
            align="center">
          </b-pagination>

          <b-button class="mr-2"
            title="Cancel"
            v-bind:disabled="downloadablePacksIsLoading"
            v-on:click="cancelClick">
            <b-icon-x />
          </b-button>
          <b-button class="mr-2" type="submit" variant="primary"
            title="Update Downloadable Pack"
            v-bind:disabled="downloadablePacksIsLoading">
            <span><b-icon-check /></span>
            <span v-if="downloadablePacksIsLoading">
              <b-spinner label="Spinning" small></b-spinner>
            </span>
          </b-button>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>

import { mapActions } from 'vuex';
import fileHandlerMixin from '../mixins/fileHandlerMixin';

export default {
  name: 'DownloadablePackDetails',
  mixins: [fileHandlerMixin],
  props: {
    downloadablePack: {},
  },
  data() {
    return {
      pageNumber: 1,
      tableFields: [
        { key: 'name', thStyle: { width: '20%' } },
        { key: 'caseName', label: 'Letter Case', thStyle: { width: '20%' } },
        { key: 'itemName', label: 'Item', thStyle: { width: '20%' } },
        { key: 'taskTypeName', label: 'Task Type', thStyle: { width: '20%' } },
        { key: 'actions', thStyle: { width: '20%' } },
      ],
      pageIsBusy: false,
    };
  },
  created() {
    if (!this.downloadablePack) {
      this.$router.go(-1);
    }
    this.initialiseUI();
  },
  computed: {
    worksheets() {
      return this.$store.state.worksheets.worksheets;
    },
    downloadablePacksIsLoading() {
      return this.$store.state.downloadablePacks.isLoading;
    },
    totalWorksheetsCount() {
      return this.$store.state.worksheets.totalWorksheetsCount;
    },
    itemsCountPerPage() {
      return this.$store.state.applicationSettings.itemsCountPerPage;
    },
    tableIsBusy() {
      return this.$store.state.worksheets.isLoading
      || this.$store.state.worksheets.isDownloadingFile;
    },
    searchWorksheetsParams() {
      return {
        id: this.downloadablePack.id,
        pageNumber: this.pageNumber,
        pageSize: this.itemsCountPerPage,
      };
    },
  },
  methods: {
    ...mapActions('downloadablePacks', ['updateDownloadablePack', 'deleteDownloadablePack']),
    ...mapActions('worksheets', ['getWorksheetsByDownloadablePackId', 'getWorksheetDocument']),
    async initialiseUI() {
      this.pageIsBusy = true;

      await this.getWorksheetsByDownloadablePackId(this.searchWorksheetsParams)
        .catch((error) => {
          this.handleHttpError(error);
          this.$store.dispatch('worksheets/clearState');
        });

      this.pageIsBusy = false;
    },
    async onPageChange(value) {
      this.pageNumber = value;

      await this.getWorksheetsByDownloadablePackId(this.searchWorksheetsParams)
        .catch((error) => {
          this.handleHttpError(error);
          this.$store.dispatch('worksheets/clearState');
        });
    },
    async downloadWorksheetClick(worksheet) {
      await this.getWorksheetDocument(worksheet.id)
        .then((response) => { this.openFile(response); })
        .catch((error) => { this.handleHttpError(error); });
    },
    cancelClick() {
      // Go back to previous page without adding a new history entry:
      this.$router.go(-1);
    },
    async formSubmit(event) {
      event.preventDefault();

      await this.updateDownloadablePack(this.downloadablePack)
        .then(() => { this.$router.go(-1); })
        .catch((error) => { this.handleHttpError(error); });
    },
  },
};
</script>
