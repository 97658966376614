import axios from 'axios';

export default {
  namespaced: true,
  state: {
    downloadablePacks: null,
    totalDownloadablePacksCount: null,
    isLoading: false,
    isDownloadingFile: false,
  },
  mutations: {
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    setDownloadablePacks(state, data) {
      state.downloadablePacks = data?.downloadablePacks ?? null;
      state.totalDownloadablePacksCount = data?.totalDownloadablePacksCount ?? null;
    },
    setIsDownloadingFile(state, isDownloadingFile) {
      state.isDownloadingFile = isDownloadingFile;
    },
  },
  actions: {
    getMyDownloadablePacks(context, request) {
      context.commit('setIsLoading', true);
      return axios.get('/api/downloadablepacks/my', {
        params: {
          pageNumber: request.pageNumber,
          pageSize: request.pageSize,
          sortBy: request.sortBy,
          isSortByDescending: request.isSortByDescending,
        },
      })
        .then((result) => {
          context.commit('setDownloadablePacks', result.data);
        })
        .catch((error) => { throw error; })
        .finally(() => {
          context.commit('setIsLoading', false);
        });
    },
    createDownloadablePack(context, downloadablePack) {
      context.commit('setIsLoading', true);
      return axios.post('/api/downloadablepacks', downloadablePack)
        .catch((error) => { throw error; })
        .finally(() => {
          context.commit('setIsLoading', false);
        });
    },
    updateDownloadablePack(context, downloadablePack) {
      context.commit('setIsLoading', true);
      return axios.put(`/api/downloadablepacks/${downloadablePack.id}`, downloadablePack)
        .catch((error) => { throw error; })
        .finally(() => {
          context.commit('setIsLoading', false);
        });
    },
    deleteDownloadablePack(context, id) {
      context.commit('setIsLoading', true);
      return axios.delete(`/api/downloadablepacks/${id}`)
        .catch((error) => { throw error; })
        .finally(() => {
          context.commit('setIsLoading', false);
        });
    },
    getDownloadablePackDocument(context, request) {
      context.commit('setIsDownloadingFile', true);
      return axios.get(`/api/downloadablepacks/${request.downloadablePackId}/document`, {
        params: {
          randomiseWorksheetOrder: request.randomiseWorksheetOrder,
        },
        responseType: 'blob',
      })
        .then((response) => response)
        .catch((error) => new Promise((resolve) => {
          const reader = new FileReader();
          reader.onload = () => {
            // eslint-disable-next-line no-param-reassign
            error.response.data = JSON.parse(reader.result);
            resolve(Promise.reject(error));
          };
          reader.readAsText(error.response.data);
        }))
        .finally(() => {
          context.commit('setIsDownloadingFile', false);
        });
    },
    clearState(context) {
      context.commit('setDownloadablePacks', null);
    },
  },
};
