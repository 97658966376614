import axios from 'axios';

// Configure initial state from localStorage if possible.
// This means that refreshing the browser does not result in the
// state appearing to be reset even though the user is still logged in.
const initialState = {
  user: JSON.parse(localStorage.getItem('user')) || null,
};

export default {
  namespaced: true,
  state: {
    ...initialState,
    isLoading: false,
  },
  mutations: {
    setLoggedIn(state, signInResult) {
      state.user = signInResult.user;
      localStorage.setItem('user', JSON.stringify(signInResult.user));
    },
    setLoggedOut(state) {
      state.user = null;
      localStorage.removeItem('user');
    },
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
  },
  getters: {
    isLoggedIn(state) {
      if (state.user) {
        return true;
      }
      return false;
    },
    loggedInUserFullName(state) {
      if (state.user) {
        return `${state.user.firstName} ${state.user.lastName}`;
      }
      return '';
    },
    isLoggedInUserInRole: (state) => (role) => {
      if (state?.user?.roleId === role) {
        return true;
      }
      return false;
    },
  },
  actions: {
    login(context, userCredentials) {
      context.commit('setIsLoading', true);
      return axios.post('/api/authentication/login', userCredentials)
        .then((result) => {
          context.commit('setLoggedIn', result.data);
        })
        .catch((error) => { throw error; })
        .finally(() => {
          context.commit('setIsLoading', false);
        });
    },
    logout(context) {
      return axios.post('/api/authentication/logout')
        .then(() => {
          context.dispatch('cart/clearCart', null, { root: true });
          context.commit('setLoggedOut');
        })
        .catch((error) => { throw error; })
        .finally(() => {
          context.commit('setIsLoading', false);
        });
    },
    refreshTokens(context) {
      context.commit('setIsLoading', true);
      return axios.post('/api/authentication/refresh')
        .catch((error) => { throw error; })
        .finally(() => {
          context.commit('setIsLoading', false);
        });
    },
    confirmAccount(context, confirmation) {
      context.commit('setIsLoading', true);
      return axios.post('/api/authentication/confirm', confirmation)
        .catch((error) => { throw error; })
        .finally(() => {
          context.commit('setIsLoading', false);
        });
    },
    resetUserPassword(context, resetPasswordRequest) {
      context.commit('setIsLoading', true);
      return axios.post('/api/authentication/resetpassword', resetPasswordRequest)
        .catch((error) => { throw error; })
        .finally(() => {
          context.commit('setIsLoading', false);
        });
    },
    requestUserConfirmation(context, request) {
      context.commit('setIsLoading', true);
      return axios.post('/api/authentication/requestconfirmation', request)
        .catch((error) => { throw error; })
        .finally(() => {
          context.commit('setIsLoading', false);
        });
    },
    requestPasswordReset(context, request) {
      context.commit('setIsLoading', true);
      return axios.post('/api/authentication/requestpasswordreset', request)
        .catch((error) => { throw error; })
        .finally(() => {
          context.commit('setIsLoading', false);
        });
    },
  },
};
