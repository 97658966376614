<template>
  <div>
    <div class="mb-3">
      <h5>Manage Organisations</h5>
    </div>

    <div class="text-center" v-if="isInitialisingUI">
      <b-spinner label="Spinning"></b-spinner>
    </div>
    <div v-else>
      <div>
        <b-table striped bordered hover
          stacked="md"
          v-bind:items="organisations"
          v-bind:fields="tableFields"
          v-bind:busy="tableIsBusy"
          show-empty
          empty-text="No data to show">
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle"></b-spinner>
            </div>
          </template>
          <template #cell(isDisabled)="data">
            <span v-if="data.item.isDisabled">Yes</span>
            <span v-else>No</span>
          </template>
          <template #cell(actions)="data">
            <b-button class="mr-2" size="sm" variant="info"
              title="Edit"
              v-on:click="editOrganisationClick(data.item)">
              <b-icon-pencil />
            </b-button>
            <b-button class="mr-2" size="sm" variant="danger"
              title="Delete"
              v-on:click="deleteOrganisationClick(data.item)">
              <b-icon-x-circle />
            </b-button>
          </template>
        </b-table>
        <b-pagination
          v-model="pageNumber"
          v-bind:total-rows="totalOrganisationsCount"
          v-bind:per-page="itemsCountPerPage"
          v-bind:disabled="tableIsBusy"
          v-on:change="onPageChange"
          align="center">
        </b-pagination>
      </div>
      <div>
        <b-button variant="primary"
        v-bind:disabled="addButtonIsDisabled"
        v-on:click="addOrganisationClick">
          Add Organisation <b-icon-plus-circle />
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'Organisations',
  created() {
    this.initialiseUI();
  },
  data() {
    return {
      pageNumber: 1,
      tableFields: [
        { key: 'name', thStyle: { width: '45%' } },
        { key: 'numberOfUsers', label: 'Users', thStyle: { width: '15%' } },
        { key: 'isDisabled', label: 'Disabled?', thStyle: { width: '15%' } },
        { key: 'actions', thStyle: { width: '25%' } },
      ],
      isInitialisingUI: false,
    };
  },
  computed: {
    organisations() {
      return this.$store.state.organisations.organisations;
    },
    totalOrganisationsCount() {
      return this.$store.state.organisations.totalOrganisationsCount;
    },
    itemsCountPerPage() {
      return this.$store.state.applicationSettings.itemsCountPerPage;
    },
    tableIsBusy() {
      return this.$store.state.organisations.isLoading;
    },
    addButtonIsDisabled() {
      return this.$store.state.organisations.isLoading;
    },
    searchParams() {
      return {
        pageNumber: this.pageNumber,
        pageSize: this.itemsCountPerPage,
      };
    },
  },
  methods: {
    ...mapActions('organisations', ['searchOrganisations', 'deleteOrganisation']),
    async initialiseUI() {
      this.isInitialisingUI = true;

      await this.searchOrganisations(this.searchParams)
        .catch((error) => {
          this.handleHttpError(error);
          this.$store.dispatch('organisations/clearState');
        });

      this.isInitialisingUI = false;
    },
    async onPageChange(value) {
      this.pageNumber = value;

      await this.searchOrganisations(this.searchParams)
        .catch((error) => {
          this.handleHttpError(error);
          this.$store.dispatch('organisations/clearState');
        });
    },
    addOrganisationClick() {
      this.$router.push({
        name: 'Admin-OrganisationDetails',
        params: {
          organisation: { id: 0, name: '' },
          new: true,
        },
      });
    },
    editOrganisationClick(organisation) {
      this.$router.push({
        name: 'Admin-OrganisationDetails',
        params: {
          organisation,
          new: false,
        },
      });
    },
    async deleteOrganisationClick(organisation) {
      const messageDiv = this.$createElement('div', [
        this.$createElement('p', [
          this.$createElement('strong', 'Warning: '),
          'Deleting an organisation will also delete all associated users!',
        ]),
        this.$createElement('p', [
          `The number of users associated with this organisation is ${organisation.numberOfUsers}.`,
        ]),
        this.$createElement('p', [
          `Are you sure you want to delete "${organisation.name}"?`,
        ]),
      ]);

      this.$bvModal.msgBoxConfirm(
        messageDiv, {
          title: 'Delete Organisation',
          okVariant: 'danger',
          centered: true,
          hideHeaderClose: false,
        },
      )
        .then(async (value) => {
          if (value) {
            await this.deleteOrganisation(organisation.id)
              .then(async () => {
                this.pageNumber = 1;
                await this.searchOrganisations(this.searchParams);
              })
              .catch((error) => { this.handleHttpError(error); });
          }
        });
    },
  },
};
</script>
