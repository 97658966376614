<template>
  <div id="app">
    <header>
      <TheHeader />
      <TheErrorAlert />
    </header>
    <main class="mb-5">
      <b-container fluid>
        <router-view />
      </b-container>
    </main>
    <footer>
      <TheFooter />
    </footer>
  </div>
</template>

<script>
import TheHeader from './components/TheHeader.vue';
import TheErrorAlert from './components/TheErrorAlert.vue';
import TheFooter from './components/TheFooter.vue';

export default {
  components: {
    TheHeader, TheErrorAlert, TheFooter,
  },
};
</script>

<style>
  /* Global styles */
  body {
    background-color: white;
  }

  header {
    background-color: white;
  }

  main {
    padding: 30px;
    background-color: white;
    min-height: 300px;
  }

  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
  }

</style>
