import axios from 'axios';

export default {
  namespaced: true,
  state: {
    users: null,
    totalUsersCount: null,
    isLoading: false,
    selectedUserId: null,
  },
  mutations: {
    setUsers(state, data) {
      state.users = data?.users ?? null;
      state.totalUsersCount = data?.totalUsersCount ?? null;
    },
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    setSelectedUserId(state, selectedUserId) {
      state.selectedUserId = selectedUserId;
    },
  },
  actions: {
    searchUsers(context, searchParams) {
      context.commit('setIsLoading', true);
      return axios.get('/api/users/search', {
        params: {
          pageNumber: searchParams.pageNumber,
          pageSize: searchParams.pageSize,
          organisationId: searchParams.organisationId,
        },
      })
        .then((result) => {
          context.commit('setUsers', result.data);
        })
        .catch((error) => { throw error; })
        .finally(() => {
          context.commit('setIsLoading', false);
        });
    },
    searchMyOrganisationUsers(context, searchParams) {
      context.commit('setIsLoading', true);
      return axios.get('/api/organisations/my/users/search', {
        params: {
          pageNumber: searchParams.pageNumber,
          pageSize: searchParams.pageSize,
        },
      })
        .then((result) => {
          context.commit('setUsers', result.data);
        })
        .catch((error) => { throw error; })
        .finally(() => {
          context.commit('setIsLoading', false);
        });
    },
    createUser(context, user) {
      context.commit('setIsLoading', true);
      return axios.post('/api/users', user)
        .catch((error) => { throw error; })
        .finally(() => {
          context.commit('setIsLoading', false);
        });
    },
    updateUser(context, user) {
      context.commit('setIsLoading', true);
      return axios.put(`/api/users/${user.id}`, user)
        .catch((error) => { throw error; })
        .finally(() => {
          context.commit('setIsLoading', false);
        });
    },
    deleteUser(context, id) {
      context.commit('setIsLoading', true);
      return axios.delete(`/api/users/${id}`)
        .catch((error) => { throw error; })
        .finally(() => {
          context.commit('setIsLoading', false);
        });
    },
    changeSelectedUser(context, selectedUserId) {
      context.commit('setSelectedUserId', selectedUserId);
    },
    clearState(context) {
      context.commit('setUsers', null);
    },
  },
};
