<template>
  <div>
    <div class="mb-3">
      <h5>Manage Sections</h5>
    </div>

    <div class="text-center" v-if="isInitialisingUI">
      <b-spinner label="Spinning"></b-spinner>
    </div>
    <div v-else>
      <div>
        <b-table striped bordered hover
          stacked="sm"
          v-bind:items="sections"
          v-bind:fields="tableFields"
          v-bind:busy="tableIsBusy"
          show-empty
          empty-text="No data to show">
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle"></b-spinner>
            </div>
          </template>
          <template #cell(actions)="data">
            <b-button class="mr-2" size="sm" variant="info"
              title="Edit"
              v-on:click="editSectionClick(data.item)">
              <b-icon-pencil />
            </b-button>
            <b-button size="sm" variant="danger"
              title="Delete"
              v-on:click="deleteSectionClick(data.item)">
              <b-icon-x-circle />
            </b-button>
          </template>
        </b-table>
        <b-pagination
          v-model="pageNumber"
          v-bind:total-rows="totalSectionsCount"
          v-bind:per-page="itemsCountPerPage"
          v-bind:disabled="tableIsBusy"
          v-on:change="onPageChange"
          align="center">
        </b-pagination>
      </div>
      <div>
        <b-button variant="primary"
        v-bind:disabled="addButtonIsDisabled"
        v-on:click="addSectionClick">
          Add Section <b-icon-plus-circle />
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'Sections',
  created() {
    this.initialiseUI();
  },
  data() {
    return {
      pageNumber: 1,
      tableFields: [
        { key: 'name', thStyle: { width: '75%' } },
        { key: 'actions', thStyle: { width: '25%' } },
      ],
      isInitialisingUI: false,
    };
  },
  computed: {
    sections() {
      return this.$store.state.sections.sections;
    },
    totalSectionsCount() {
      return this.$store.state.sections.totalSectionsCount;
    },
    itemsCountPerPage() {
      return this.$store.state.applicationSettings.itemsCountPerPage;
    },
    tableIsBusy() {
      return this.$store.state.sections.isLoading;
    },
    addButtonIsDisabled() {
      return this.$store.state.sections.isLoading;
    },
    searchParams() {
      return {
        pageNumber: this.pageNumber,
        pageSize: this.itemsCountPerPage,
      };
    },
  },
  methods: {
    ...mapActions('sections', ['searchSections', 'deleteSection']),
    async initialiseUI() {
      this.isInitialisingUI = true;

      await this.searchSections(this.searchParams)
        .catch((error) => {
          this.handleHttpError(error);
          this.$store.dispatch('sections/clearState');
        });

      this.isInitialisingUI = false;
    },
    async onPageChange(value) {
      this.pageNumber = value;

      await this.searchSections(this.searchParams)
        .catch((error) => {
          this.handleHttpError(error);
          this.$store.dispatch('sections/clearState');
        });
    },
    addSectionClick() {
      this.$router.push({
        name: 'Admin-SectionDetails',
        params: {
          section: { id: 0, name: '' },
          new: true,
        },
      });
    },
    editSectionClick(section) {
      this.$router.push({
        name: 'Admin-SectionDetails',
        params: {
          section,
          new: false,
        },
      });
    },
    async deleteSectionClick(section) {
      this.$bvModal.msgBoxConfirm(
        `Are you sure you want to delete "${section.name}"?`, {
          title: 'Delete Section',
          okVariant: 'danger',
          centered: true,
          hideHeaderClose: false,
        },
      )
        .then(async (value) => {
          if (value) {
            await this.deleteSection(section.id)
              .then(async () => {
                this.pageNumber = 1;
                await this.searchSections(this.searchParams);
              })
              .catch((error) => { this.handleHttpError(error); });
          }
        });
    },
  },
};
</script>
